/*
    Name: WAL WEBAPP
    Version: 1.0
    Author: Apper
    Author URI: http://apper.ch/
*/
/* Table of Contents
--------------------------------------------------------------
    /* Base      
    /* Typoghraphy
    /* Helpers    
    /* Header    
    /* Footer    
    /* Buttons
    /* Forms    
    /* Pages    
--------------------------------------------------------------

/* General Styles
-------------------------------------------------------------- */
html, body {
  font-family: "Steradian", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  width: 100% !important;
  margin: 0 !important;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

::selection {
  background: #bea58b;
  color: #ffffff;
}

::-moz-selection {
  background: #bea58b;
  color: #ffffff;
}

.main {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  overflow: hidden;
}

@media (min-width: 1600px) {
  .container-fluid {
    width: 1470px;
  }
}
/* Typography Style
-------------------------------------------------------------- */
@font-face {
  font-family: "Steradian";
  src: url("../fonts/steradian/SteradianBold.eot");
  src: url("../fonts/steradian/SteradianBold.eot?#iefix") format("embedded-opentype"), url("../fonts/steradian/SteradianBold.woff") format("woff"), url("../fonts/steradian/SteradianBold.ttf") format("truetype"), url("../fonts/steradian/SteradianBold.svg#Steradian") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Steradian";
  src: url("../fonts/steradian/SteradianMedium.eot");
  src: url("../fonts/steradian/SteradianMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/steradian/SteradianMedium.woff") format("woff"), url("../fonts/steradian/SteradianMedium.ttf") format("truetype"), url("../fonts/steradian/SteradianMedium.svg#Steradian") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Steradian";
  src: url("../fonts/steradian/SteradianRegular.eot");
  src: url("../fonts/steradian/SteradianRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/steradian/SteradianRegular.woff") format("woff"), url("../fonts/steradian/SteradianRegular.ttf") format("truetype"), url("../fonts/steradian/SteradianRegular.svg#Steradian") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Steradian";
  src: url("../fonts/steradian/SteradianLight.eot");
  src: url("../fonts/steradian/SteradianLight.eot?#iefix") format("embedded-opentype"), url("../fonts/steradian/SteradianLight.woff") format("woff"), url("../fonts/steradian/SteradianLight.ttf") format("truetype"), url("../fonts/steradian/SteradianLight.svg#Steradian") format("svg");
  font-weight: 300;
  font-style: normal;
}
h1 {
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  color: #191d26;
}

h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  color: #191d26;
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  color: #191d26;
}

h1, h2, p {
  padding: 0;
  margin: 0;
  clear: both;
}

a {
  color: #191d26;
}
a:hover, a:active, a:focus {
  text-decoration: none;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Icon Style
-------------------------------------------------------------- */
@font-face {
  font-family: "wal-icon";
  src: url("../fonts/wal-icon/wal-icon.eot?zbn0ai");
  src: url("../fonts/wal-icon/wal-icon.eot?zbn0ai#iefix") format("embedded-opentype"), url("../fonts/wal-icon/wal-icon.ttf?zbn0ai") format("truetype"), url("../fonts/wal-icon/wal-icon.woff?zbn0ai") format("woff"), url("../fonts/wal-icon/wal-icon.svg?zbn0ai#wal-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  font-family: "wal-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-linkedin-alt:before {
  content: "\e97c";
}

.icon-instagram:before {
  content: "\e97d";
}

.icon-food:before {
  content: "\e97b";
}

.icon-health:before {
  content: "\e97a";
}

.icon-packages:before {
  content: "\e979";
}

.icon-no-internet:before {
  content: "\e978";
}

.icon-calls-canceled:before {
  content: "\e977";
}

.icon-exit-full-screen:before {
  content: "\e976";
  color: #fff;
}

.icon-video-call:before {
  content: "\e96e";
}

.icon-call-pause:before {
  content: "\e96f";
  color: #fff;
}

.icon-calls-general:before {
  content: "\e970";
}

.icon-full-screen:before {
  content: "\e971";
  color: #fff;
}

.icon-mute:before {
  content: "\e972";
  color: #fff;
}

.icon-speaker:before {
  content: "\e973";
  color: #fff;
}

.icon-speaker-off:before {
  content: "\e974";
  color: #fff;
}

.icon-video-call-off:before {
  content: "\e975";
  color: #fff;
}

.icon-menu-alt:before {
  content: "\e96d";
  color: #a7a9ac;
}

.icon-retry:before {
  content: "\e96c";
}

.icon-image-report:before {
  content: "\e96b";
  color: #9d9d9e;
}

.icon-image:before {
  content: "\e96a";
  color: #a7a9ac;
}

.icon-notification-off:before {
  content: "\e969";
  color: #9d9d9e;
}

.icon-linkedin:before {
  content: "\e966";
  color: #9d9d9e;
}

.icon-twitter:before {
  content: "\e967";
  color: #9d9d9e;
}

.icon-facebook-alt:before {
  content: "\e968";
  color: #9d9d9e;
}

.icon-contracts-report:before {
  content: "\e964";
  color: #9d9d9e;
}

.icon-email:before {
  content: "\e965";
  color: #9d9d9e;
}

.icon-article:before {
  content: "\e963";
  color: #a7a9ac;
}

.icon-report-lawyer:before {
  content: "\e95c";
  color: #a7a9ac;
}

.icon-share:before {
  content: "\e95b";
  color: #9d9d9e;
}

.icon-add-attachment:before {
  content: "\e900";
  color: #a7a9ac;
}

.icon-add-image:before {
  content: "\e901";
  color: #a7a9ac;
}

.icon-appstore:before {
  content: "\e902";
  color: #bea58b;
}

.icon-arcticles:before {
  content: "\e903";
  color: #a7a9ac;
}

.icon-back:before {
  content: "\e904";
}

.icon-bookmarks:before {
  content: "\e905";
  color: #a7a9ac;
}

.icon-bookmarks-filled:before {
  content: "\e906";
}

.icon-bought-items:before {
  content: "\e907";
  color: #a7a9ac;
}

.icon-calendar:before {
  content: "\e908";
  color: #9d9d9e;
}

.icon-call:before {
  content: "\e909";
  color: #9d9d9e;
}

.icon-card-cvv:before {
  content: "\e90a";
  color: #9d9d9e;
}

.icon-cases:before {
  content: "\e90b";
  color: #9d9d9e;
}

.icon-categories:before {
  content: "\e90c";
  color: #a7a9ac;
}

.icon-center:before {
  content: "\e90d";
  color: #a7a9ac;
}

.icon-chat:before {
  content: "\e90e";
  color: #a7a9ac;
}

.icon-close-cases:before {
  content: "\e90f";
  color: #9d9d9e;
}

.icon-companies:before {
  content: "\e910";
  color: #9d9d9e;
}

.icon-contracts:before {
  content: "\e911";
  color: #a7a9ac;
}

.icon-copy:before {
  content: "\e912";
  color: #a7a9ac;
}

.icon-court:before {
  content: "\e913";
  color: #a7a9ac;
}

.icon-creditcard-cc:before {
  content: "\e914";
  color: #a7a9ac;
}

.icon-dashboard:before {
  content: "\e915";
  color: #9d9d9e;
}

.icon-delete:before {
  content: "\e916";
  color: #a7a9ac;
}

.icon-done-check:before {
  content: "\e917";
  color: #a7a9ac;
}

.icon-download:before {
  content: "\e918";
  color: #a7a9ac;
}

.icon-edit:before {
  content: "\e919";
  color: #a7a9ac;
}

.icon-error-circle:before {
  content: "\e91a";
  color: #a7a9ac;
}

.icon-eye:before {
  content: "\e91b";
  color: #a7a9ac;
}

.icon-facebook-logo:before {
  content: "\e91c";
  color: #9d9d9e;
}

.icon-favorite:before {
  content: "\e91d";
  color: #a7a9ac;
}

.icon-filters:before {
  content: "\e91e";
  color: #a7a9ac;
}

.icon-financial-companies:before {
  content: "\e91f";
  color: #a7a9ac;
}

.icon-financial-history:before {
  content: "\e920";
  color: #9d9d9e;
}

.icon-financial-lawyers:before {
  content: "\e921";
  color: #a7a9ac;
}

.icon-financial-overview:before {
  content: "\e922";
  color: #a7a9ac;
}

.icon-google-logo:before {
  content: "\e923";
  color: #a7a9ac;
}

.icon-go-to:before {
  content: "\e924";
  color: #a7a9ac;
}

.icon-half-x:before {
  content: "\e925";
  color: #a7a9ac;
}

.icon-id-check:before {
  content: "\e926";
  color: #a7a9ac;
}

.icon-language:before {
  content: "\e927";
  color: #a7a9ac;
}

.icon-lawyers:before {
  content: "\e928";
  color: #9d9d9e;
}

.icon-locations:before {
  content: "\e929";
  color: #a7a9ac;
}

.icon-lock:before {
  content: "\e92a";
  color: #a7a9ac;
}

.icon-log-out:before {
  content: "\e92b";
  color: #a7a9ac;
}

.icon-mastercard:before {
  content: "\e92c";
  color: #9d9d9e;
}

.icon-match-deal:before {
  content: "\e92d";
  color: #a7a9ac;
}

.icon-menu:before {
  content: "\e92e";
  color: #a7a9ac;
}

.icon-minus:before {
  content: "\e92f";
  color: #a7a9ac;
}

.icon-next:before {
  content: "\e930";
  color: #a7a9ac;
}

.icon-notification:before {
  content: "\e931";
  color: #9d9d9e;
}

.icon-online:before {
  content: "\e932";
  color: #a7a9ac;
}

.icon-open-cases:before {
  content: "\e933";
  color: #9d9d9e;
}

.icon-open-cases-docs:before {
  content: "\e934";
  color: #9d9d9e;
}

.icon-options:before {
  content: "\e935";
  color: #a7a9ac;
}

.icon-order-history:before {
  content: "\e936";
  color: #a7a9ac;
}

.icon-payments:before {
  content: "\e937";
  color: #a7a9ac;
}

.icon-pdf-file:before {
  content: "\e938";
  color: #a7a9ac;
}

.icon-pin:before {
  content: "\e939";
  color: #a7a9ac;
}

.icon-playstore:before {
  content: "\e93a";
  color: #bea58b;
}

.icon-plus:before {
  content: "\e93b";
  color: #a7a9ac;
}

.icon-price-tag:before {
  content: "\e93c";
  color: #a7a9ac;
}

.icon-purchase:before {
  content: "\e93d";
  color: #a7a9ac;
}

.icon-push-notification:before {
  content: "\e93e";
  color: #9d9d9e;
}

.icon-revenue:before {
  content: "\e93f";
  color: #9d9d9e;
}

.icon-search:before {
  content: "\e940";
  color: #9d9d9e;
}

.icon-send:before {
  content: "\e941";
  color: #a7a9ac;
}

.icon-settings:before {
  content: "\e942";
  color: #9d9d9e;
}

.icon-star:before {
  content: "\e943";
  color: #a7a9ac;
}

.icon-star-filled:before {
  content: "\e944";
  color: #bea58b;
}

.icon-star-outline:before {
  content: "\e945";
  color: #a7a9ac;
}

.icon-subscribtion:before {
  content: "\e946";
  color: #a7a9ac;
}

.icon-tick:before {
  content: "\e947";
  color: #a7a9ac;
}

.icon-time-used:before {
  content: "\e948";
  color: #a7a9ac;
}

.icon-triangle:before {
  content: "\e949";
}

.icon-type-contracts:before {
  content: "\e94a";
  color: #a7a9ac;
}

.icon-type-criminal:before {
  content: "\e94b";
  color: #a7a9ac;
}

.icon-type-damage:before {
  content: "\e94c";
  color: #a7a9ac;
}

.icon-type-international:before {
  content: "\e94d";
  color: #a7a9ac;
}

.icon-type-order-build:before {
  content: "\e94e";
  color: #a7a9ac;
}

.icon-type-pay:before {
  content: "\e94f";
  color: #a7a9ac;
}

.icon-type-people-families:before {
  content: "\e950";
  color: #a7a9ac;
}

.icon-type-sport:before {
  content: "\e951";
  color: #a7a9ac;
}

.icon-type-state-administrative:before {
  content: "\e952";
  color: #a7a9ac;
}

.icon-type-taxes:before {
  content: "\e953";
  color: #a7a9ac;
}

.icon-type-tech:before {
  content: "\e954";
  color: #a7a9ac;
}

.icon-type-trade:before {
  content: "\e955";
  color: #a7a9ac;
}

.icon-type-transport:before {
  content: "\e956";
  color: #a7a9ac;
}

.icon-type-worker:before {
  content: "\e957";
  color: #a7a9ac;
}

.icon-unpublish:before {
  content: "\e958";
  color: #a7a9ac;
}

.icon-upload:before {
  content: "\e959";
  color: #9d9d9e;
}

.icon-users:before {
  content: "\e95a";
  color: #9d9d9e;
}

.icon-visa-cc:before {
  content: "\e95d";
  color: #9d9d9e;
}

.icon-warning-info:before {
  content: "\e95e";
  color: #a7a9ac;
}

.icon-world:before {
  content: "\e95f";
  color: #a7a9ac;
}

.icon-x:before {
  content: "\e960";
  color: #a7a9ac;
}

.icon-zoom-in:before {
  content: "\e961";
  color: #9d9d9e;
}

.icon-zoom-out:before {
  content: "\e962";
  color: #9d9d9e;
}

/* Helpers Style
-------------------------------------------------------------- */
.clearfix {
  clear: both;
}

.no-pd {
  padding: 0 !important;
}

.no-mg {
  margin: 0 !important;
}

.pd-rl {
  padding: 0 60px;
}

.mg-top-260 {
  margin-top: 260px;
}

.mg-top-220 {
  margin-top: 220px;
}

.mg-top-180 {
  margin-top: 180px;
}

.mg-top-160 {
  margin-top: 160px;
}

.mg-top-145 {
  margin-top: 145px;
}

.mg-top-130 {
  margin-top: 130px;
}

.mg-top-120 {
  margin-top: 120px;
}

.mg-top-112 {
  margin-top: 112px;
}

.mg-top-80 {
  margin-top: 80px;
}

.mg-top-50 {
  margin-top: 50px;
}

.mg-top-40 {
  margin-top: 40px;
}

.center-text {
  text-align: center;
}
.center-img {
  text-align: center;
}
.center-img img {
  margin: 0 auto;
}

.wal-video {
  position: absolute;
  width: auto;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  transition: all 0.3s ease;
}
.wal-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(25, 29, 38, 0.95);
  opacity: 0.8;
  z-index: -1;
  filter: blur(10px);
}
.wal-video-title {
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.wal-video-title h1 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.wal-video-frame {
  position: relative;
  height: 170px;
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.wal-video-frame::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
}
.wal-video-frame img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.wal-video-frame .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.wal-video-button {
  display: none;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  border: none;
  border-radius: 7px;
  background-color: #bea58b;
  outline: 0;
  transition: all 0.3s ease;
}
.wal-video-button:focus {
  outline: 0;
}
.wal-video-button svg {
  fill: #ffffff;
  margin-left: 8px;
}
.wal-video-button:hover {
  background-color: #ac8c6a;
}
.wal-video.sticky {
  position: fixed;
  transition: all 0.3s ease;
}
.wal-video.sticky::before {
  content: none;
}
.wal-video.sticky .wal-video-title {
  display: none;
}
.wal-video.sticky .wal-video-frame {
  display: none;
}
.wal-video.sticky .wal-video-content button {
  display: block;
  transition: all 0.3s ease;
}
.wal-video.hide {
  right: -25em;
}

.mfp-container {
  background-color: rgba(25, 29, 38, 0.8);
}
.mfp-close {
  position: relative;
  top: 0px !important;
  right: 0px !important;
  padding: 0px !important;
  height: auto;
  line-height: normal;
  display: flex;
  cursor: pointer;
}
.mfp-close::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  width: 24px;
  background-image: url(../img/icons/x.svg);
  background-repeat: no-repeat;
}
.mfp-close-wrap {
  position: absolute;
  top: -50px;
  right: 0;
  display: flex;
  height: 30px;
  width: 30px;
}

/* General Style
-------------------------------------------------------------- */
.dropdown-menu {
  border-radius: 7px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  left: auto;
  right: 0;
  top: 70px;
  margin: 0;
  padding: 0;
  z-index: 1;
  -webkit-user-select: none;
          user-select: none;
}
.dropdown-menu li {
  padding: 10px 20px;
  width: 100%;
}
.dropdown-menu li a {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  color: #191d26;
}
.dropdown-menu li a i {
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  margin-right: 15px;
}
.dropdown-menu li a i::before {
  color: #191d26;
}
.dropdown-menu li a.log-out {
  color: #d92344;
}
.dropdown-menu li a.log-out i::before {
  color: #d92344;
}
.dropdown-menu .more-options {
  padding: 0px 20px;
}
.dropdown-menu .more-options span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
}
.dropdown-options.show .options {
  background-color: rgba(190, 165, 139, 0.3);
}

.main-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.main-title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
}
.main-title h2 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
}
.main-title a {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
  color: #bea58b;
}
.main-title a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(0, 1);
}
.main-title a:hover::after {
  opacity: 1;
  transform: scale(1);
}

.status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.status.active {
  background-color: #05f48a;
}
.status.inactive {
  background-color: #d92344;
}
.status.offline {
  background-color: #d6d6d6;
}

.wal-content {
  position: relative;
  width: 100%;
  float: left;
}
.wal-content-head {
  margin-bottom: 40px;
}
.wal-content-head .wal-form-group {
  margin-bottom: 0px;
}
.wal-content-title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
}
.wal-content-title h1 span {
  font-size: 18px;
  font-weight: 400;
  color: #9d9d9e;
}

.wal-filter {
  padding: 0 24px;
  border: 1px solid #ededed;
  border-radius: 10px;
  min-height: 830px;
  height: 830px;
  display: flex;
  flex-direction: column;
}
.wal-filter-head {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
}
.wal-filter-head h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
}
.wal-filter-clear {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  cursor: pointer;
}
.wal-filter-clear i {
  margin-right: 8px;
}
.wal-filter-clear span {
  font-size: 16px;
  font-weight: 300;
  color: #191d26;
}
.wal-filter-body {
  padding: 32px 0 40px;
}
.wal-filter-footer {
  padding: 32px 0;
  border-top: 1px solid #e4e4e4;
  margin-top: auto;
}
.wal-filter-footer .button {
  font-size: 16px;
  width: 100%;
}

.wal-results {
  position: relative;
  padding: 16px 8px 0px 16px;
  border-radius: 10px;
  height: 830px;
  border: 1px solid #ededed;
}
.wal-results-wrap {
  height: calc(100% - 16px);
  overflow-y: auto;
  padding-right: 8px;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.wal-results-wrap::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.wal-results-wrap::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.wal-results-wrap::-webkit-scrollbar-thumb {
  background: #c1beb9;
}

.wal-icons {
  display: flex;
  align-items: center;
}
.wal-icons-button {
  margin-left: 24px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bea58b;
  background-color: rgba(190, 165, 139, 0.1);
  border-radius: 8px;
  font-size: 24px;
  color: #bea58b;
  transition: all 0.5s ease;
}
.wal-icons-button i::before {
  color: #bea58b;
}
.wal-icons-button:hover {
  background-color: rgba(190, 165, 139, 0.3);
  transition: all 0.5s ease;
}

.profile {
  position: relative;
  width: 100%;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: flex-end;
}
.profile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 29, 38, 0.95);
}
.profile-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 480px;
  padding-top: 140px;
}
.profile-top-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.profile-top-wrap a {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}
.profile-top-wrap a.back-button {
  display: flex;
  align-items: center;
}
.profile-top-wrap a.back-button i {
  margin-right: 16px;
}
.profile-top-wrap a.bookmarks {
  font-size: 24px;
}
.profile-top-wrap a.bookmarks i.icon-bookmarks-filled::before {
  color: #ffffff;
}
.profile-bottom {
  margin-top: auto;
  margin-bottom: 24px;
}
.profile-info {
  position: relative;
  display: flex;
  align-items: flex-end;
}
.profile-info-top {
  display: flex;
  align-items: center;
}
.profile-info-top .lawyers-type {
  margin-left: 24px;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: rgba(157, 157, 158, 0.1);
}
.profile-info-top .lawyers-type:hover .lawyers-type-tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
}
.profile-info-wrap span {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
}
.profile-image {
  width: 184px;
  height: 200px;
  margin-right: 32px;
}
.profile-image img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.profile-status {
  display: flex;
  align-items: center;
}
.profile-status .status {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.profile-status span {
  text-transform: uppercase;
}
.profile-name {
  margin: 16px 0;
  display: flex;
  align-items: center;
}
.profile-name h1 {
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
}
.profile-name .verify-badge img {
  width: 24px;
  height: 24px;
}
.profile-rating-state {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.profile-rating-state .profile-type {
  margin-right: 24px;
}
.profile-rating {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.profile-rating span {
  margin-left: 8px;
}
.profile-state {
  display: flex;
  align-items: center;
}
.profile-state i {
  margin-right: 8px;
}
.profile-type {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.profile-type .type {
  color: #bea58b;
  margin-right: 16px;
}
.profile-type i {
  font-size: 16px;
  margin-right: 8px;
}
.profile-type .more-list {
  display: inline-block;
  cursor: pointer;
}
.profile-type .more-list ul {
  display: none;
  position: absolute;
  padding: 8px 16px;
  background-color: rgba(9, 10, 13, 0.9);
  border-radius: 10px;
  max-width: 340px;
  width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
}
.profile-type .more-list ul li {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
.profile-type .more-list ul li:not(:last-child) {
  margin-bottom: 8px;
}
.profile-type .more-list:hover ul {
  display: block;
}
.profile-tags ul li {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 8px;
}
.profile-tags ul li a {
  display: block;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(230, 230, 230, 0.1);
  border-radius: 5px;
  text-transform: uppercase;
}
.profile-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.profile-buttons .button {
  width: auto;
  padding: 0 20px;
}
.profile-content {
  position: relative;
  width: 100%;
  float: left;
}
.profile-content .main-title {
  margin-bottom: 30px;
}
.profile-tab {
  margin-bottom: 50px;
  border-bottom: 1px solid #ededed;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
  -webkit-user-select: none;
          user-select: none;
}
.profile-tab::-webkit-scrollbar {
  height: 5px;
  border-radius: 2px;
}
.profile-tab::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.profile-tab::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.profile-tab li {
  flex-shrink: 0;
  margin-bottom: 0px !important;
}
.profile-tab li:not(:last-child) {
  margin-right: 40px;
}
.profile-tab li .nav-link {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #9d9d9e;
  padding: 8px 0;
  border: none;
}
.profile-tab li .nav-link.active {
  color: #bea58b;
}
.profile-tab li .nav-link.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -1px;
  background-color: #bea58b;
}
.profile-tab-content .panel-title a {
  position: relative;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  background-color: #f0f0f0;
  border: 1px solid transparent;
  overflow: hidden;
  color: #9d9d9e;
  transition: background-color 0.5s ease;
}
.profile-tab-content .panel-title a::before {
  content: "\e93b";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 10px;
  font-family: "wal-icon" !important;
  color: #000000;
  transition: color 0.5s ease;
  pointer-events: none;
}
.profile-tab-content .panel-title a[aria-expanded=true] {
  background-color: #bea58b;
  transition: background-color 0.5s ease;
  color: #ffffff;
}
.profile-tab-content .panel-title a[aria-expanded=true]::before {
  content: "\e92f";
  color: #ffffff;
  transition: color 0.5s ease;
}
.profile-article h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 24px;
}
.profile-article h3 {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 8px;
}
.profile-article p {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 48px;
}
.profile-article p a {
  margin-top: 16px;
}
.profile-article ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-bottom: 40px;
}
.profile-article ul li {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #191d26;
  line-height: 20px;
  margin-bottom: 6px;
  padding-left: 10px;
}
.profile-article ul li::before {
  content: "";
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 5px;
  height: 1px;
  background-color: #191d26;
}
.profile-article img {
  max-width: 100%;
  margin: 8px 0 16px;
}
.profile-aside {
  padding-left: 40px;
  border-left: 1px solid #e4e4e4;
}
.profile-aside-title h3 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 25px;
}
.profile-aside-content h3 {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 8px;
  margin-top: 40px;
}
.profile-aside-content p {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.profile-aside-content-item {
  position: relative;
}
.profile-aside-content-item .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.profile-aside-map {
  height: 200px;
  border-radius: 10px;
  width: 100%;
  margin: 24px 0;
  background-color: #f0f0f0;
  overflow: hidden;
}
.profile-aside-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-aside-date {
  margin-top: 50px;
}
.profile-aside-date h3 {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 8px;
}
.profile-aside-date p {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.profile-aside-copy {
  margin-top: 50px;
  padding-top: 30px;
  border-top: 1px solid #ededed;
}
.profile-aside-social {
  padding: 30px 0;
  border-bottom: 1px solid #ededed;
}
.profile-aside-social ul li {
  display: inline-block;
}
.profile-aside-social ul li a {
  border: 1px solid #f7f7f7;
  border-radius: 8px;
  padding: 8px;
}
.profile-aside-social ul li a i::before {
  color: #191d26;
}
.profile-aside-social ul li:not(:last-child) {
  margin-right: 24px;
}
.profile-create-item {
  background-color: #f7f7f7;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 16px;
}
.profile-create-right {
  display: flex;
  align-items: center;
}
.profile-create-right .lawyers-img {
  margin-right: 16px;
}
.profile-create-left .chat {
  font-size: 24px;
}
.profile-create-img {
  height: 56px;
  width: 56px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.06), 0 0px 10px rgba(0, 0, 0, 0.09);
  margin-right: 16px;
}
.profile-create-img img {
  max-width: 50%;
}
.profile-create-content h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 8px;
}
.profile-create-content p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.profile-reviews {
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
}
.profile-reviews-item {
  display: flex;
  align-items: center;
}
.profile-reviews-item:not(:last-of-type) {
  margin-bottom: 8px;
}
.profile-reviews-item span {
  flex: auto;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.profile-reviews-wrap {
  margin-bottom: 64px;
}
.profile-reviews-wrap .profile-reviews {
  margin-bottom: 0px;
}
.profile-reviews-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.profile-reviews-input {
  display: flex;
  width: 60%;
}
.profile-reviews-input .comments-img img {
  height: 40px;
  width: 40px;
}
.profile-reviews-input textarea.wal-form-control {
  resize: none;
  height: 100%;
  margin-right: 0px;
  padding: 11px 16px;
}
.profile-options-dropdown {
  cursor: pointer;
  margin-left: 24px;
}
.profile-options-dropdown .wal-icons-button {
  margin-left: 0px;
}
.profile-lawyers {
  margin-bottom: 40px;
}
.profile-lawyers .button {
  margin-top: 25px;
}
.profile-gallery-item {
  position: relative;
  height: 195px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;
}
.profile-gallery-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.profile-package::before {
  background: linear-gradient(90deg, rgb(41, 50, 79) 0%, rgb(116, 129, 165) 100%);
}

.wal-result-none {
  position: relative;
  width: 100%;
  float: left;
  margin: 80px 0 40px;
}
.wal-result-none-wrap {
  text-align: center;
}
.wal-result-none-wrap h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 16px;
}
.wal-result-none-wrap p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 40px;
}
.wal-result-none-icon {
  font-size: 40px;
  color: #191d26;
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(190, 165, 139, 0.1);
  margin: 0 auto;
  margin-bottom: 24px;
}
.wal-result-none-icon i::before {
  color: #191d26;
}

.copy-link {
  position: relative;
}
.copy-link .copy {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  font-size: 20px;
  cursor: pointer;
}
.copy-link .copy::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #e4e4e4;
  top: 0;
  left: -16px;
}
.copy-link .wal-form-control {
  padding-right: 70px;
}

.comments {
  position: relative;
  width: 100%;
}
.comments-head {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e4e4e4;
}
.comments-head .comments-img img {
  width: 40px;
  height: 40px;
}
.comments-img {
  margin-right: 16px;
}
.comments-img img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.comments-input {
  display: flex;
  width: 100%;
}
.comments-input .wal-form-control {
  border-radius: 5px;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  margin-right: 11px;
}
.comments-input .button {
  height: 40px;
  padding: 0 20px;
}
.comments-body {
  padding-top: 24px;
}
.comments-item {
  position: relative;
  margin-bottom: 32px;
}
.comments-info {
  display: flex;
  margin-bottom: 16px;
}
.comments-info-wrap h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 8px;
}
.comments-info-wrap span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.comments-info-bottom {
  display: flex;
  align-items: center;
}
.comments-content {
  font-size: 16px;
  font-weight: 400;
  color: #5e6067;
}
.comments-content a {
  color: #bea58b;
}
.comments-content .delete {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #d92344;
  margin-top: 8px;
  cursor: pointer;
}

.wal-pagination {
  margin-top: 30px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.wal-pagination-link {
  position: relative;
  display: flex;
  padding: 5px 12px;
  font-size: 16px;
  color: #9d9d9e;
  transition: all 0.5s ease;
  cursor: pointer;
}
.wal-pagination-link:hover {
  color: #191d26;
  transition: all 0.5s ease;
}
.wal-pagination-link:hover i::before {
  color: #191d26;
}
.wal-pagination-item.active .wal-pagination-link {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #191d26;
}
.wal-pagination-item:focus .wal-pagination-link {
  outline: none;
}
.wal-pagination-item:focus .wal-pagination-link i::before {
  color: #191d26;
}

.slider-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}
.slider-controls button {
  border-radius: 0;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 6px;
  height: 16px;
  margin: 0 20px;
}
.slider-controls button i::before {
  color: #9d9d9e;
}
.slider-controls button:focus {
  outline: none;
}
.slider-controls button:focus i::before {
  color: #191d26;
}

.choose-plan {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-plan a {
  width: 210px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 300;
  padding: 15px;
  background: #ffffff;
  color: rgba(25, 29, 38, 0.4);
  border: 1px solid #ededed;
  border-collapse: collapse;
  text-align: center;
}
.choose-plan a:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 0;
}
.choose-plan a:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.choose-plan a.active {
  background: linear-gradient(0deg, rgba(190, 165, 139, 0.2) 0%, rgba(238, 232, 224, 0) 100%);
  border: 1px solid #bea58b;
  color: #191d26;
}

.subscription-plan-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 40px;
}
.subscription-plan-item {
  position: relative;
  width: 290px;
  max-width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
  overflow: hidden;
}
.subscription-plan-item:not(:last-of-type) {
  margin-right: 40px;
}
.subscription-plan-item.active {
  border: 1px solid #bea58b;
}
.subscription-plan-item .info {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(190, 165, 139, 0.1);
}
.subscription-plan-item .info i {
  font-size: 16px;
  margin-right: 8px;
}
.subscription-plan-item .info i::before {
  color: #bea58b;
}
.subscription-plan-item .info span {
  font-size: 10px;
  font-weight: 500;
  color: #bea58b;
  text-transform: uppercase;
}
.subscription-plan-head {
  padding: 16px 0;
  border-bottom: 1px solid #ededed;
}
.subscription-plan-head h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
}
.subscription-plan-head span.sub-info {
  display: inline-block;
  margin-top: 8px;
  padding: 6px 13px;
  background-color: #d8ffda;
  border-radius: 7px;
  font-size: 10px;
  font-weight: 400;
  color: #5ab960;
}
.subscription-plan-body {
  padding: 24px 0;
}
.subscription-plan-body ul li {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
}
.subscription-plan-body ul li span {
  font-weight: 400;
}
.subscription-plan-body ul li:not(:last-child) {
  margin-bottom: 20px;
}
.subscription-plan-footer {
  padding-top: 24px;
  border-top: 1px solid #ededed;
  margin-top: auto;
}
.subscription-plan-footer .price {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
}
.subscription-plan-footer .month {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.subscription-plan-footer .button {
  margin-top: 24px;
}

.wal-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 24px;
  padding: 4px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  border-radius: 16px;
}
.wal-status-active {
  color: #2a9f31;
  background-color: #e7f9e4;
}
.wal-status-rejected {
  color: #d92344;
  background-color: #fcf2f4;
}
.wal-status-pending {
  color: #5e6067;
  background-color: #f0f0f0;
}

/* Header Style
-------------------------------------------------------------- */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  transition: background-color 0.3s linear;
  transition: 0.5s;
}
.header.sticky {
  background-color: #191d26;
  box-shadow: 0 0px 2px 0 rgba(25, 29, 38, 0.9);
  transition: background-color 0.3s linear;
  transition: 0.5s;
}
.header-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.1);
}
.header-nav {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-nav ul li {
  display: inline-block;
  margin: 0 20px;
}
.header-nav ul li a {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 40px 0 32px;
  color: #9d9d9e;
  transition: all 0.5s ease;
}
.header-nav ul li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #bea58b;
  transition: width 0.3s;
}
.header-nav ul li:hover a {
  color: #bea58b;
  transition: all 0.5s ease;
}
.header-nav ul li:hover a::before {
  width: 100%;
}
.header-nav ul li.active a {
  color: #bea58b;
}
.header-nav ul li.active a::before {
  width: 100%;
}
.header-right {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0;
}
.header .button {
  height: 40px;
}
.header-search {
  margin-right: 32px;
}
.header-search a {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-search a i::before {
  color: #ffffff;
}
.header-messages {
  margin-right: 32px;
}
.header-messages a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-messages a i::before {
  color: #ffffff;
}
.header-messages a .messages-number {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #ff6868;
  border-radius: 50%;
}
.header-messages-menu {
  width: 360px;
  top: 77px;
}
.header-messages-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}
.header-messages-top h3 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
}
.header-messages-top a {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  cursor: pointer;
}
.header-messages-body {
  position: relative;
  width: 100%;
  max-height: 430px;
  padding: 0 15px;
  overflow-y: auto;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.header-messages-body::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.header-messages-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.header-messages-body::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.header-messages-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.header-messages-item.message-unread::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
  width: 4px;
  height: 100%;
  background-color: #bea58b;
}
.header-messages-item.message-unread .header-messages-info span {
  color: #191d26;
}
.header-messages-right {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.header-messages-img {
  position: relative;
  margin-right: 8px;
}
.header-messages-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.header-messages-img .status {
  position: absolute;
  top: 2px;
  right: 2px;
  border: 1px solid #f7f7f7;
}
.header-messages-info {
  min-width: 0;
  overflow: hidden;
}
.header-messages-info h1 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}
.header-messages-info span {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-messages-left {
  flex: 0 0 auto;
  margin-left: 16px;
}
.header-messages-left .time {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.header-messages-older {
  margin-top: 8px;
  margin-bottom: 17px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
}
.header-messages-older h3 {
  font-size: 12px;
  font-weight: 400;
  color: #191d26;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.header-notifications {
  margin-right: 32px;
}
.header-notifications a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-notifications a i::before {
  color: #ffffff;
}
.header-notifications a .notifications-number {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #ff6868;
  border-radius: 50%;
}
.header-notifications-menu {
  width: 360px;
  top: 77px;
}
.header-notifications-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}
.header-notifications-top h3 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
}
.header-notifications-top a {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  cursor: pointer;
}
.header-notifications-body {
  position: relative;
  width: 100%;
  max-height: 430px;
  padding: 0 15px;
  overflow-y: auto;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.header-notifications-body::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.header-notifications-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.header-notifications-body::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.header-notifications-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
}
.header-notifications-right {
  position: relative;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  margin-right: 8px;
}
.header-notifications-img {
  position: relative;
  margin-right: 8px;
}
.header-notifications-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.header-notifications-info {
  min-width: 0;
  overflow: hidden;
}
.header-notifications-info span {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.header-notifications-info span.info {
  font-weight: 500;
}
.header-notifications-left {
  flex: 0 0 auto;
}
.header-notifications-left .time {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.header-notifications-older {
  margin-top: 8px;
  margin-bottom: 17px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
}
.header-notifications-older h3 {
  font-size: 12px;
  font-weight: 400;
  color: #191d26;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.header-notifications-buttons {
  display: flex;
  margin-top: 15px;
}
.header-notifications-buttons .button {
  height: 30px;
  line-height: 30px;
}
.header-notifications-buttons .button:not(:last-of-type) {
  margin-right: 15px;
}
.header-profile {
  position: relative;
  cursor: pointer;
  flex: 0;
}
.header-profile-info {
  display: flex;
  align-items: center;
}
.header-profile-icon {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.header-profile-icon i::before {
  color: #ffffff;
}
.header-profile-img {
  flex: 0 0 48px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}
.header-profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.header-profile-img i {
  font-size: 20px;
}
.header-profile-img i::before {
  color: #9d9d9e;
}
.header-profile-img-company img {
  height: auto;
  width: 50%;
}
.header-profile-name {
  position: relative;
  padding-right: 40px;
  -webkit-user-select: none;
          user-select: none;
}
.header-profile-name h1 {
  font-size: 16px;
  color: #ffffff;
}
.header-profile-name::before {
  content: "\e904";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 16px;
  font-family: "wal-icon" !important;
  color: #ffffff;
  transform: translateY(-50%) rotate(-90deg);
}
.header-profile .dropdown-menu li a i {
  border: none;
  padding: 0px;
}
.header-button {
  position: relative;
  display: flex;
  align-items: center;
}
.header-button a:not(:last-of-type) {
  margin-right: 16px;
}
.header-button a.button-login {
  width: 120px;
}
.header-button a.link-about {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease;
}
.header-button a.link-about img {
  opacity: 0.5;
  transition: all 0.5s ease;
}
.header-button a.link-about:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.5s ease;
}
.header-button a.link-about:hover img {
  opacity: 1;
  transition: all 0.5s ease;
}
.header-button a.link-about:hover .info-tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
  top: 60px;
}
.header-button-mobile {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.header-pg .header-wrap {
  border-color: #e4e4e4;
}
.header-pg.sticky {
  background-color: #ffffff;
}
.header-pg .header-search i::before, .header-pg .header-messages i::before, .header-pg .header-notifications i::before {
  color: #191d26;
}
.header-pg .header-profile-icon i::before {
  color: #191d26;
}
.header-pg .header-profile-name h1 {
  color: #191d26;
}
.header-pg .header-profile-name::before {
  color: #191d26;
}
.header-pg .header-button a.link-about {
  border-color: #e4e4e4;
}
.header-pg .header-button a.link-about img {
  opacity: 1;
}
.header .button {
  width: 160px;
}

/* Footer Style
-------------------------------------------------------------- */
.footer {
  position: relative;
  width: 100%;
  float: left;
  background-color: #191d26;
}
.footer p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.footer p a {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.5s ease;
}
.footer p a:hover {
  transition: color 0.5s ease;
  color: #ffffff;
}
.footer-top {
  padding: 82px 0 62px;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
}
.footer-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-info p {
  margin-top: 33px;
}
.footer-links span {
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.footer-links ul li a {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.5s ease;
}
.footer-links ul li a:hover {
  transition: color 0.5s ease;
  color: #ffffff;
}
.footer-links ul li:not(:last-child) {
  margin-bottom: 16px;
}
.footer-bottom {
  padding: 40px 0 60px;
}
.footer-bottom-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}
.footer-register {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.footer-register a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.footer-language .language-dropdown-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.footer-language .language-dropdown-name {
  margin: 0 8px;
}
.footer-language .language-dropdown-name span {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}
.footer-language .language-dropdown .dot {
  display: block;
  margin-left: 6px;
  width: 4px;
  height: 4px;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid #ffffff;
  border-radius: 1px;
}
.footer-language .language-dropdown-menu {
  border-radius: 8px;
  background-color: rgba(9, 10, 13, 0.85);
  border: none;
  left: 50%;
  transform: translateX(-50%);
}
.footer-language .language-dropdown-menu li {
  padding: 15px 24px;
}
.footer-language .language-dropdown-menu li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  opacity: 0.5;
  transition: all 0.5s ease;
}
.footer-language .language-dropdown-menu li a:hover {
  opacity: 1;
  transition: all 0.5s ease;
}
.footer-language .language-dropdown-menu li a img {
  margin-right: 8px;
}
.footer-language .language-dropdown-menu li.active a {
  opacity: 1;
}
.footer-downloads a:not(:last-of-type) {
  margin-bottom: 16px;
}
.footer-currency a {
  display: flex;
  align-items: center;
}
.footer-currency a img {
  margin-right: 8px;
}
.footer-currency a span {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.footer-currency a .dot {
  display: block;
  margin-left: 6px;
  width: 4px;
  height: 4px;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid #ffffff;
  border-radius: 1px;
}
.footer-social ul {
  display: flex;
  align-items: center;
  gap: 32px;
}
.footer-social ul li a {
  font-size: 16px;
  color: #a7a9ac;
}
.footer-social ul li a i::before {
  transition: all 0.5s ease;
}
.footer-social ul li a:hover i::before {
  color: #ffffff;
  transition: all 0.5s ease;
}

/* Buttons Style
-------------------------------------------------------------- */
.main-menu-indicator {
  display: none;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 999;
  height: 30px;
  width: 20px;
  background: transparent;
  outline: 0;
  position: absolute;
  right: 0;
}
.main-menu-indicator:hover {
  border: 0;
  box-shadow: none;
}
.main-menu-indicator:focus {
  outline: none;
}
.main-menu-indicator > span {
  transition: all 0.1s ease 0s;
  position: absolute;
  width: 19px;
  height: 2px;
  top: 15px;
  right: 0;
  background-color: #a7a9ac;
}
.main-menu-indicator > span::before {
  transition: all 0.2s ease 0s;
  position: absolute;
  content: "";
  width: 19px;
  height: 2px;
  background-color: #a7a9ac;
  left: 0;
}
.main-menu-indicator > span::after {
  transition: all 0.2s ease 0s;
  position: absolute;
  content: "";
  width: 19px;
  height: 2px;
  background-color: #a7a9ac;
  left: 0;
  top: -5px;
}
.main-menu-indicator > span::before {
  bottom: -5px;
}
.main-menu-indicator.is-open > span {
  background-color: transparent;
}
.main-menu-indicator.is-open > span::after {
  top: 0;
  transform: rotate(45deg);
  transform-origin: center center;
  background-color: #ffffff;
}
.main-menu-indicator.is-open > span::before {
  bottom: 0;
  transform: rotate(-45deg);
  transform-origin: center center;
  background-color: #ffffff;
}

.button {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.button:hover {
  transition: all 0.5s ease;
}
.button:focus {
  outline: none;
}
.button i {
  margin-right: 8px;
}
.button i::before {
  transition: color 0.5s ease;
}
.button-primary {
  background-color: #bea58b;
  border: 1px solid #bea58b;
  color: #ffffff !important;
}
.button-primary i::before {
  color: #ffffff;
}
.button-primary:hover {
  background-color: #ac8c6a;
  color: #ffffff !important;
}
.button-transparent {
  background-color: rgba(190, 165, 139, 0.1);
  border: 1px solid #bea58b;
  color: #bea58b !important;
}
.button-transparent i::before {
  color: #bea58b;
}
.button-transparent:hover {
  background-color: #bea58b;
  color: #ffffff !important;
}
.button-transparent:hover i::before {
  color: #ffffff;
  transition: color 0.5s ease;
}
.button-transparent-border {
  border: 1px solid transparent;
}
.button-bl {
  background-color: #191d26;
  border: 1px solid #191d26;
  color: #ffffff !important;
}
.button-bl:hover {
  background-color: #050607;
  color: #ffffff !important;
}
.button-bl i::before {
  color: #ffffff;
}
.button-gray {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  color: #5e6067 !important;
}
.button-gray:hover {
  background-color: #d7d7d7;
  color: #5e6067 !important;
}
.button-gray i::before {
  color: #5e6067;
}
.button-white {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  color: #191d26 !important;
}
.button-white:hover {
  background-color: #e6e6e6;
  color: #191d26 !important;
}
.button-white i::before {
  color: #191d26;
}
.button-danger {
  background-color: #d92344;
  border: 1px solid #d92344;
  color: #ffffff !important;
}
.button-danger:hover {
  background-color: #ad1c36;
  color: #ffffff !important;
}
.button-danger i::before {
  color: #ffffff;
}
.button:disabled, .button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

/* Animations Style
--------------------------------------------------------------*/
@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}
/* Form Style
-------------------------------------------------------------- */
.wal-form {
  position: relative;
  width: 100%;
}
.wal-form-group {
  margin-bottom: 30px;
}
.wal-form-group label {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9e;
  margin-bottom: 8px;
}
.wal-form-group-wrap {
  display: flex;
  align-items: center;
  gap: 24px;
}
.wal-form-control {
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #191d26;
  text-align: left;
  transition: all 0.5s ease;
  padding: 0 35px 0 15px;
  appearance: none;
  -webkit-appearance: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.wal-form-control::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(157, 157, 158, 0.5);
}
.wal-form-control:focus {
  outline: none;
  border: 1px solid #bea58b;
  background-color: #ffffff;
  box-shadow: none;
  transition: all 0.5s ease;
}
.wal-form textarea.wal-form-control {
  padding: 14px;
  height: 155px;
  resize: none;
  white-space: normal;
  overflow: auto;
}
.wal-form-search::before {
  content: "\e940";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 16px;
  font-family: "wal-icon" !important;
  color: #9d9d9e;
  pointer-events: none;
}
.wal-form-sort {
  display: flex;
  align-items: center;
}
.wal-form-sort label {
  margin-bottom: 0px;
  flex: 0 0 auto;
  margin-right: 15px;
}
.wal-form-sort-wrap {
  position: relative;
  width: 100%;
}
.wal-form-sort-wrap::before {
  content: "\e949";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 8px;
  font-family: "wal-icon" !important;
  color: #191d26;
  pointer-events: none;
}
.wal-form-sort-wrap .wal-form-control {
  color: #191d26;
}
.wal-form-range input {
  width: 100%;
}
.wal-form-checkbox .checkbox:checked, .wal-form-checkbox .checkbox:not(:checked) {
  display: none;
  position: absolute;
  left: -9999px;
}
.wal-form-checkbox .checkbox:checked + label, .wal-form-checkbox .checkbox:not(:checked) + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 0px;
  -webkit-user-select: none;
          user-select: none;
}
.wal-form-checkbox .checkbox:checked + label::before, .wal-form-checkbox .checkbox:not(:checked) + label::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #9d9d9e;
  background: #ffffff;
  border-radius: 4px;
}
.wal-form-checkbox .checkbox:checked + label::after, .wal-form-checkbox .checkbox:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #bea58b;
  position: absolute;
  top: 50%;
  left: 2px;
  border-radius: 2px;
  transition: all 0.2s ease;
}
.wal-form-checkbox .checkbox:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}
.wal-form-checkbox .checkbox:checked + label {
  color: #bea58b;
}
.wal-form-checkbox .checkbox:checked + label::before {
  border: 1px solid #bea58b;
}
.wal-form-checkbox .checkbox:checked + label::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.wal-form-checkbox .checkbox:disabled:checked + label::before, .wal-form-checkbox .checkbox:disabled:not(:checked) + label::before {
  border-color: #bea58b;
  background-color: #f0f0f0;
}
.wal-form-checkbox .checkbox:disabled:checked + label::after {
  background: #e4e4e4;
}
.wal-form-checkbox-main .checkbox:checked + label, .wal-form-checkbox-main .checkbox:not(:checked) + label {
  font-weight: 500;
  color: #191d26;
}
.wal-form-radio .radio:checked, .wal-form-radio .radio:not(:checked) {
  display: none;
  position: absolute;
  left: -9999px;
}
.wal-form-radio .radio:checked + label, .wal-form-radio .radio:not(:checked) + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 0px;
}
.wal-form-radio .radio:checked + label::before, .wal-form-radio .radio:not(:checked) + label::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #9d9d9e;
  background: #ffffff;
  border-radius: 50px;
}
.wal-form-radio .radio:checked + label::after, .wal-form-radio .radio:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #bea58b;
  position: absolute;
  top: 5px;
  left: 2px;
  border-radius: 50px;
  transition: all 0.2s ease;
}
.wal-form-radio .radio:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}
.wal-form-radio .radio:checked + label {
  color: #bea58b;
}
.wal-form-radio .radio:checked + label::before {
  border: 1px solid #bea58b;
}
.wal-form-radio .radio:checked + label::after {
  opacity: 1;
  transform: scale(1);
}
.wal-form-radio .radio:disabled:checked + label::before, .wal-form-radio .radio:disabled:not(:checked) + label::before {
  border-color: #bea58b;
  background-color: #f0f0f0;
}
.wal-form-radio .radio:disabled:checked + label::after {
  background: #e4e4e4;
}
.wal-form-type .radio:checked, .wal-form-type .radio:not(:checked) {
  display: none;
  position: absolute;
  left: -9999px;
}
.wal-form-type .radio:checked + label, .wal-form-type .radio:not(:checked) + label {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  color: #191d26;
  padding: 12px;
  padding-left: 40px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 0px;
  transition: all 0.5s ease;
}
.wal-form-type .radio:checked + label i, .wal-form-type .radio:not(:checked) + label i {
  font-size: 24px;
  margin-right: 8px;
}
.wal-form-type .radio:checked + label i::before, .wal-form-type .radio:not(:checked) + label i::before {
  transition: all 0.5s ease;
  color: #191d26;
}
.wal-form-type .radio:checked + label::after, .wal-form-type .radio:not(:checked) + label::after {
  content: "\e947";
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "wal-icon" !important;
  font-size: 14px;
  right: 16px;
  color: #ffffff;
  transition: all 0.2s ease;
}
.wal-form-type .radio:checked + label .wal-form-warning, .wal-form-type .radio:not(:checked) + label .wal-form-warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: all 0.5s ease;
}
.wal-form-type .radio:checked + label .wal-form-warning:hover, .wal-form-type .radio:not(:checked) + label .wal-form-warning:hover {
  background-color: #e4e4e4;
  transition: all 0.5s ease;
}
.wal-form-type .radio:checked + label .wal-form-warning::before, .wal-form-type .radio:not(:checked) + label .wal-form-warning::before {
  content: "";
  left: auto;
  right: 0;
}
.wal-form-type .radio:checked + label .wal-form-warning i, .wal-form-type .radio:not(:checked) + label .wal-form-warning i {
  margin-right: 0px;
  font-size: 16px;
}
.wal-form-type .radio:checked + label .wal-form-warning .info-tooltip, .wal-form-type .radio:not(:checked) + label .wal-form-warning .info-tooltip {
  top: 60px;
  z-index: 1;
}
.wal-form-type .radio:not(:checked) + label::after {
  opacity: 0;
}
.wal-form-type .radio:checked + label {
  background-color: #bea58b;
  color: #ffffff;
}
.wal-form-type .radio:checked + label i::before {
  transition: all 0.5s ease;
  color: #ffffff;
}
.wal-form-type .radio:checked + label::after {
  opacity: 1;
}
.wal-form-type .radio:checked + label .wal-form-warning:hover {
  background-color: #a78e73;
}
.wal-form-type .radio:disabled:checked + label::after {
  background: #e4e4e4;
}
.wal-form-info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
}
.wal-form-password {
  position: relative;
}
.wal-form-password .wal-form-control {
  padding: 0 100px 0 15px;
}
.wal-form-password .toggle-password {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.wal-form-password .pass-eye::before {
  content: "\e91b";
  font-family: "wal-icon" !important;
  font-size: 24px;
  color: #bea58b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wal-form-password .pass-eye-off::before {
  content: "\e91b";
  font-family: "wal-icon" !important;
  font-size: 24px;
  color: #9d9d9e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wal-form-warning {
  cursor: pointer;
  display: flex;
  position: relative;
  padding: 0 8px;
  color: #e4e4e4;
  font-size: 24px;
}
.wal-form-warning i::before {
  color: #9d9d9e;
}
.wal-form-warning::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #e4e4e4;
}
.wal-form-warning:hover .info-tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}
.wal-form-error .wal-form-control {
  border: 1px solid #d92344;
}
.wal-form-error .error {
  text-align: center;
  margin-top: 8px;
}
.wal-form-error .error span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  color: #d92344;
}
.wal-form-dropdown .dropdown-toggle::after {
  content: "\e949";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 8px;
  font-family: "wal-icon" !important;
  color: #191d26;
  pointer-events: none;
  border: 0;
  margin: 0;
}
.wal-form-dropdown-wrap {
  width: 100%;
  height: 430px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 0px;
  margin: 0px;
  margin-top: 8px;
  top: auto;
  z-index: 1;
}
.wal-form-dropdown-list {
  height: calc(100% - 50px);
  overflow-y: auto;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
  user-select: none;
  -webkit-user-select: none;
}
.wal-form-dropdown-list::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.wal-form-dropdown-list::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.wal-form-dropdown-list::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.wal-form-dropdown-search {
  position: relative;
  padding: 0 15px;
  border-bottom: 1px solid #e4e4e4;
}
.wal-form-dropdown-search input {
  border: 0;
  background-color: #ffffff;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  text-align: left;
  letter-spacing: -0.3px;
  padding-left: 30px;
  -webkit-appearance: none;
  appearance: none;
}
.wal-form-dropdown-search input:focus {
  outline: none;
}
.wal-form-dropdown-search::before {
  content: "\e940";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 16px;
  font-family: "wal-icon" !important;
  color: #9d9d9e;
  pointer-events: none;
}
.wal-form-dropdown-item {
  position: relative;
}
.wal-form-dropdown-item .wal-form-checkbox {
  padding: 0 15px;
  transition: all 0.5s ease;
}
.wal-form-dropdown-item .wal-form-checkbox:hover {
  transition: all 0.5s ease;
  background-color: #f7f7f7;
}
.wal-form-dropdown-item .wal-form-checkbox label {
  padding: 15px 0 15px 30px;
  width: 100%;
}
.wal-form-dropdown-item span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #191d26;
  border-bottom: 1px solid #e4e4e4;
  padding: 15px;
  cursor: pointer;
}
.wal-form-dropdown-item span i {
  margin-right: 15px;
  font-size: 24px;
}
.wal-form-dropdown-item span i::before {
  color: #191d26;
}
.wal-form-dropdown-item span::before {
  content: "\e930";
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 16px;
  font-family: "wal-icon" !important;
  color: #191d25;
  pointer-events: none;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.5s ease;
}
.wal-form-dropdown-item .wal-form-checkbox-main {
  position: relative;
}
.wal-form-dropdown-item .wal-form-checkbox-main.has-items::before {
  content: "\e930";
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 16px;
  font-family: "wal-icon" !important;
  color: #191d25;
  pointer-events: none;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.5s ease;
}
.wal-form-dropdown-item .wal-form-checkbox-main + div {
  border-top: 1px solid #e4e4e4;
}
.wal-form-dropdown-item:not(:first-of-type) span {
  border-top: 1px solid #e4e4e4;
}
.wal-form-dropdown-item:not(:first-of-type) .wal-form-checkbox-main {
  border-top: 1px solid #e4e4e4;
}
.wal-form-filter-icon {
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

[type=search]::-ms-clear,
[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

[type=search]::-webkit-search-decoration,
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-results-button,
[type=search]::-webkit-search-results-decoration {
  display: none;
}

.wal-group-tabs {
  overflow-x: auto;
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-direction: row;
  border-bottom: 0px;
}
.wal-group-tabs li {
  max-width: 50%;
  width: 100%;
  display: flex;
  flex: 0 0 50%;
}
.wal-group-tabs li.nav-item {
  margin-bottom: 0px;
}
.wal-group-tabs li .nav-link {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  color: #9d9d9e;
  text-align: center;
  border: none;
  padding: 15px;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.wal-group-tabs li .nav-link.active {
  border-color: #bea58b;
  color: #191d26;
}
.wal-group-tabs li .nav-link:hover {
  color: #191d26;
}
.wal-group-tabs li:not(:first-child) .nav-link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - 30px);
  background-color: #e4e4e4;
}

.payment-radio {
  width: 100%;
  margin-bottom: 8px;
}
.payment-radio .radio:checked, .payment-radio .radio:not(:checked) {
  display: none;
  position: absolute;
  left: -9999px;
}
.payment-radio .radio:checked + label, .payment-radio .radio:not(:checked) + label {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 40px 8px 8px;
  margin-bottom: 0px;
  border: 1px solid #ededed;
  border-radius: 8px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.payment-radio .radio:checked + label::before, .payment-radio .radio:not(:checked) + label::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid #9d9d9e;
  background: #ffffff;
  border-radius: 50%;
}
.payment-radio .radio:checked + label::after, .payment-radio .radio:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #bea58b;
  position: absolute;
  top: 50%;
  right: 18px;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.payment-radio .radio:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}
.payment-radio .radio:checked + label::before {
  border: 1px solid #bea58b;
}
.payment-radio .radio:checked + label::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.payment-radio .radio:disabled:checked + label::before, .payment-radio .radio:disabled:not(:checked) + label::before {
  border-color: #bea58b;
  background-color: #f0f0f0;
}
.payment-radio .radio:disabled:checked + label::after {
  background: #e4e4e4;
}
.payment-radio-icon {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: #26337A;
}
.payment-radio-content h3 {
  font-size: 14px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 6px;
}
.payment-radio-content .card-number {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
  display: block;
}

.payment-form-group .wal-form-control {
  padding: 0 15px 0 50px;
}
.payment-form-icon {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0 8px;
  border-right: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
}
.payment-form-icon i {
  font-size: 18px;
}
.payment-form-icon i::before {
  color: #9d9d9e;
}
.payment-form-info {
  text-align: center;
  margin-bottom: 30px;
}
.payment-form-info p {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.payment-form-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.payment-form-buttons .button:not(:last-of-type) {
  margin-right: 25px;
}
.payment-form .error {
  margin-top: 0px;
  margin-bottom: 4px;
}
.payment-form .error span {
  font-size: 10px;
  line-height: normal;
}

.wal-form-date .wal-form-control {
  padding-right: 15px;
  padding-left: 45px;
}
.wal-form-date .wal-form-control i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-size: 16px;
}
.wal-form-date .wal-form-control i::before {
  color: #191d26;
}
.wal-form-date-menu {
  width: 690px;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}
.wal-form-date .calendar {
  position: relative;
  display: flex;
  padding: 18px 32px 32px 8px;
}
.wal-form-date .calendar-sidebar {
  flex: 0 0 auto;
  margin-top: 55px;
  margin-right: 24px;
}
.wal-form-date .calendar-sidebar ul li {
  width: 136px;
  flex: 0 0 136px;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  cursor: pointer;
}
.wal-form-date .calendar-sidebar ul li:not(:last-child) {
  margin-bottom: 8px;
}
.wal-form-date .calendar-sidebar ul li.active {
  background-color: #191d26;
  color: #ffffff;
  border-color: #191d26;
}
.wal-form-date .calendar-months {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}
.wal-form-date .calendar-months span {
  font-size: 18px;
  font-weight: 500;
  color: rgba(25, 29, 38, 0.3);
  margin: 0 6px;
  cursor: pointer;
}
.wal-form-date .calendar-months span sup {
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
}
.wal-form-date .calendar-months span.active {
  color: #191d26;
}
.wal-form-date .calendar-months .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wal-form-date .calendar-months .icon i::before {
  color: #191d26;
}
.wal-form-date .calendar-months .icon.prev {
  left: 0;
}
.wal-form-date .calendar-months .icon.next {
  right: 0;
}
.wal-form-date .calendar-days {
  padding: 4px 0 20px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  text-align: center;
  font-size: 0;
}
.wal-form-date .calendar-days-wrap span {
  position: relative;
  display: inline-block;
  width: 13%;
  height: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #191d26;
  text-align: center;
  cursor: pointer;
  margin: 12px 0;
}
.wal-form-date .calendar-days-wrap span.label {
  color: #bea58b;
  cursor: initial;
}
.wal-form-date .calendar-days-wrap span.inactive {
  color: rgba(25, 29, 38, 0.3);
}
.wal-form-date .calendar-days-wrap span.start-date, .wal-form-date .calendar-days-wrap span.end-date {
  background-color: #191d26;
  color: #ffffff;
  border-radius: 5px;
}
.wal-form-date .calendar-days-wrap span.in-range {
  background-color: #f0f0f0;
}
.wal-form-date .calendar-days-wrap span.in-range:nth-child(7n) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.wal-form-date .calendar-days-wrap span.in-range:nth-child(7n+1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.wal-form-date .calendar-buttons {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 32px;
}
.wal-form-date .calendar-buttons .button:not(:last-of-type) {
  margin-right: 32px;
}

.wal-form-profile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wal-form-profile-img {
  height: 104px;
  width: 104px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wal-form-profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.wal-form-profile-img-company img {
  height: auto;
  width: 50%;
}
.wal-form-profile-img i {
  font-size: 40px;
}
.wal-form-profile-img i::before {
  color: #9d9d9e;
}
.wal-form-profile-upload {
  position: relative;
}
.wal-form-profile-upload .button {
  width: 220px;
  max-width: 100%;
  height: 40px;
}
.wal-form-profile-upload .wal-form-profile-file {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.wal-form-profile-remove {
  margin-top: 24px;
}
.wal-form-profile-remove a {
  font-size: 14px;
  font-weight: 300;
  color: #d92344;
}
.wal-form-profile-type {
  text-align: center;
}
.wal-form-profile-type-wrap {
  display: inline-block;
  text-align: left;
}
.wal-form-profile-type-wrap h3 {
  font-size: 16px;
  font-weight: 300;
  color: #9d9d9e;
}
.wal-form-profile-type-item {
  position: relative;
  width: 220px;
  max-width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  border-radius: 7px;
  margin-top: 24px;
}
.wal-form-profile-type-item span {
  font-size: 16px;
  font-weight: 300;
  color: #191d26;
}
.wal-form-profile-type-item i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  font-size: 16px;
  display: none;
  opacity: 0;
  visibility: hidden;
}
.wal-form-profile-type-item i::before {
  color: #ffffff;
}
.wal-form-profile-type-item.active {
  background-color: #191d26;
  border: 1px solid #191d26;
}
.wal-form-profile-type-item.active span {
  color: #ffffff;
}
.wal-form-profile-type-item.active i {
  display: block;
  opacity: 1;
  visibility: visible;
}

.wal-alert {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  width: 600px;
  max-width: 100%;
  padding: 0 15px;
  z-index: 3;
}
.wal-alert-wrap {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wal-alert-left {
  display: flex;
  align-items: center;
}
.wal-alert-icon {
  font-size: 40px;
  margin-right: 16px;
}
.wal-alert-message p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}
.wal-alert-message span {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9e;
}
.wal-alert-close {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.wal-alert-close i {
  font-size: 16px;
}
.wal-alert-close i::before {
  color: #191d26;
}
.wal-alert-success .wal-alert-message p {
  color: #8bbe8e;
}
.wal-alert-success .wal-alert-icon i::before {
  color: #8bbe8e;
}
.wal-alert-error .wal-alert-message p {
  color: #d92344;
}
.wal-alert-error .wal-alert-icon i::before {
  color: #d92344;
}
.wal-alert-info .wal-alert-message p {
  color: #adc3ff;
}
.wal-alert-info .wal-alert-icon i::before {
  color: #adc3ff;
}

.wal-cards-item {
  position: relative;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  margin-bottom: 8px;
}
.wal-cards-item span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 8px;
}
.wal-cards-item h5 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 0px;
}
.wal-cards-item div:not(last-of-type) {
  margin-right: 10px;
}
.wal-cards-icon i {
  font-size: 36px;
}
.wal-cards-icon i::before {
  color: #191d26;
}
.wal-cards-icon img {
  width: 36px;
  height: 36px;
}
.wal-cards-delete a {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 7px;
  border: 1px solid;
  border-color: rgba(217, 35, 68, 0.1);
}
.wal-cards-delete a i {
  font-size: 16px;
}
.wal-cards-delete a i::before {
  color: #d92344;
}
.wal-cards-add-new {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.wal-cards-add-new .button {
  width: 450px;
  max-width: 100%;
}

.credit-card {
  width: 100%;
  height: 226px;
  border-radius: 7px;
  box-shadow: -5px 39px 100px 3px rgba(0, 0, 0, 0.07);
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.54);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 250, 245) 100%);
  padding: 16px;
}
.credit-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.credit-card-head i {
  font-size: 40px;
  opacity: 0.5;
}
.credit-card-head i::before {
  color: #191d26;
}
.credit-card-body {
  margin-top: 30px;
}
.credit-card-body-item:not(:last-of-type) {
  margin-bottom: 20px;
}
.credit-card span {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.credit-card p {
  font-size: 14px;
  font-weight: 400;
  color: #191d26;
}
.credit-card-info {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 35px;
}
.credit-card-info p {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.credit-card-buttons {
  display: flex;
  align-items: center;
}
.credit-card-buttons .button:not(:last-of-type) {
  margin-right: 16px;
}

.react-tel-input {
  font-family: inherit;
}
.react-tel-input .form-control {
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #191d26;
  transition: all 0.5s ease;
}
.react-tel-input .form-control::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: rgba(157, 157, 158, 0.5);
}
.react-tel-input .form-control:focus {
  outline: none;
  border: 1px solid #bea58b;
  background-color: #ffffff;
  box-shadow: none;
  transition: all 0.5s ease;
}
.react-tel-input .form-control.open {
  border-radius: 8px 8px 0 0;
}
.react-tel-input .flag-dropdown {
  border: 1px solid #e4e4e4;
  border-radius: 8px 0 0 8px;
}
.react-tel-input .flag-dropdown.open {
  background-color: rgba(190, 165, 139, 0.2);
  border-radius: 8px 0 0 0px;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: rgba(190, 165, 139, 0.2);
  border-radius: 8px 0 0 0px;
}
.react-tel-input .country-list {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
}
.react-tel-input .country-list .country {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9e;
}
.react-tel-input .country-list .country .highlight, .react-tel-input .country-list .country:hover {
  background-color: #f1ece8;
}
.react-tel-input .country-list .country .dial-code {
  color: #191d26;
}
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover {
  background: transparent;
}

a[href^="http://maps.google.com/maps"], a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

/* Search Style
--------------------------------------------------------------*/
.search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  padding: 40px 0;
}
.search.show {
  opacity: 1;
  visibility: visible;
  overflow-y: auto;
}
.search-on {
  overflow-y: hidden;
}
.search-top {
  position: relative;
  margin-bottom: 40px;
}
.search-close {
  position: absolute;
  top: 8px;
  right: -40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
}
.search-form {
  position: relative;
}
.search-form .search-input {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  border: 1px solid #ededed;
  padding-left: 50px;
}
.search-form .search-input:focus {
  outline: none;
}
.search-form .search-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.search-form i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  position: absolute;
  font-size: 16px;
}
.search-menu ul {
  border-bottom: 1px solid #ededed;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.search-menu ul li {
  position: relative;
  position: relative;
  display: inline-block;
  margin: 0 20px;
  cursor: pointer;
}
.search-menu ul li a {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: #9d9d9e;
  padding: 8px 0;
}
.search-menu ul li.active a, .search-menu ul li:hover a {
  color: #bea58b;
}
.search-menu ul li.active::before, .search-menu ul li:hover::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #bea58b;
}
.search-result {
  position: relative;
  margin-top: 25px;
  text-align: right;
}
.search-result p {
  font-size: 14px;
  font-weight: 300;
  color: #bea58b;
}
.search-result-title h1 {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin: 25px 0 10px;
}
.search-result-item {
  position: relative;
  margin-bottom: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  min-height: 64px;
}
.search-result-item a {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.search-result-img {
  position: relative;
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  margin-right: 15px;
}
.search-result-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.search-result-img .status {
  position: absolute;
  top: 2px;
  right: 2px;
}
.search-result-icon {
  display: flex;
  font-size: 24px;
  margin-right: 24px;
}
.search-result-icon i::before {
  color: #191d26;
}
.search-result-text {
  display: flex;
  align-items: center;
}
.search-result-text h1 {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f92;
  margin-right: 16px;
}
.search-result-text span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.search-suggestion {
  margin-top: 40px;
  padding-left: 50px;
}
.search-suggestion ul li {
  margin-bottom: 8px;
}
.search-suggestion ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #9d9d9e;
  transition: all 0.5s ease;
}
.search-suggestion ul li a:hover {
  color: #191d26;
  transition: all 0.5s ease;
}

/* Modal Style
--------------------------------------------------------------*/
.modal-ge {
  background-color: rgba(25, 29, 38, 0.6);
}
.modal-ge .modal-content {
  border: 0;
  border-radius: 15px;
}
.modal-ge-wrap {
  padding: 0 40px;
}
.modal-ge-top {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 40px;
  margin: 40px 0;
}
.modal-ge-top h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 30px;
}
.modal-ge-top p {
  font-size: 16px;
  font-weight: 300;
  color: #5e6067;
}
.modal-ge-bottom {
  border-top: 1px solid #e4e4e4;
  padding-top: 30px;
  margin-bottom: 40px;
}
.modal-ge-bottom-buttons {
  display: flex;
}
.modal-ge-bottom-buttons a:not(:last-of-type) {
  margin-right: 25px;
}
.modal-ge-close {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
}
.modal-ge-close i::before {
  color: #191d26;
}
.modal-ge-icon {
  width: 56px;
  height: 56px;
  border: 1px solid #ededed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 32px;
}
.modal-ge-icon i {
  font-size: 24px;
}
.modal-ge-content {
  padding: 40px 0 25px;
  text-align: center;
}
.modal-ge-content h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 32px;
}
.modal-ge-content p {
  font-size: 16px;
  font-weight: 300;
  color: #5e6067;
  margin-bottom: 48px;
}
.modal-ge-content a {
  margin-bottom: 15px;
}

.login-modal {
  background-color: rgba(25, 29, 38, 0.6);
}
.login-modal .modal-content {
  border: 0;
  border-radius: 15px;
}
.login-wrap {
  padding: 0 40px;
}
.login-wrap .nav-tabs {
  border-bottom: 0;
}
.login-wrap .nav a {
  position: relative;
  padding: 40px 0;
  font-size: 18px;
  font-weight: 700;
  color: #9d9d9e;
  margin-right: 40px;
}
.login-wrap .nav a.active {
  color: #191d26;
}
.login-wrap .nav a.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #bea58b;
}
.login-other {
  position: relative;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.login-other::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 100%;
  width: calc(100% + 48px);
  border-radius: 8px;
  background-color: #191d26;
}
.login-other a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.15);
  cursor: pointer;
}
.login-other a:hover {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.15);
}
.login-other a i {
  font-size: 24px;
  margin-right: 7px;
}
.login-other a:not(:last-of-type) {
  margin-right: 24px;
}
.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.login-forgot a {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  transition: all 0.5s ease;
}
.login-forgot a:hover {
  transition: all 0.5s ease;
  color: #bea58b;
}
.login-reset-top {
  padding: 40px 0 65px;
}
.login-reset-top h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
}
.login-reset-top a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  color: #5e6067;
  cursor: pointer;
}
.login-reset-top a i {
  margin-right: 16px;
}
.login-reset-content p {
  font-size: 16px;
  font-weight: 300;
  color: #5e6067;
  margin-bottom: 40px;
}
.login-button {
  margin: 0 0 40px 0;
  padding-top: 32px;
  border-top: 1px solid #e4e4e4;
}
.login-close {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
}
.login-close i::before {
  color: #191d26;
}

.subscription-modal-wrap {
  padding: 40px;
}
.subscription-modal-wrap .modal-ge-top {
  margin-top: 0px;
  padding-bottom: 25px;
}
.subscription-modal-wrap .subscription-plan-wrap {
  margin-top: 0px;
}
.subscription-modal-wrap .subscription-plan-head {
  min-height: 92px;
}
.subscription-modal-wrap .subscription-plan-head h1 {
  text-transform: uppercase;
}
.subscription-modal-icon {
  width: 56px;
  height: 56px;
  border: 1px solid #ededed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 32px;
}
.subscription-modal-icon i {
  font-size: 24px;
}
.subscription-modal-content {
  text-align: center;
}
.subscription-modal-content h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 24px;
}
.subscription-modal-content p {
  font-size: 16px;
  font-weight: 300;
  color: #5e6067;
  margin-bottom: 42px;
}
.subscription-modal-content .button:not(:last-of-type) {
  margin-bottom: 16px;
}
.subscription-modal-plan .modal-dialog {
  max-width: 760px;
}
.subscription-modal-info {
  padding: 40px 0 0;
  text-align: center;
}
.subscription-modal-info p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}

.payment-modal-top {
  border-bottom: none;
  padding-bottom: 0px;
}
.payment-modal-title {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 8px;
  margin-bottom: 15px;
}
.payment-modal-title h3 {
  font-size: 12px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 0px;
}
.payment-modal-info {
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.payment-modal-info::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 100%;
  width: calc(100% + 48px);
  background-color: rgba(190, 165, 139, 0.1);
  border-radius: 8px;
}
.payment-modal-info-wrap h2 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 8px;
}
.payment-modal-info-wrap p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.payment-modal-info-price span {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #191d26;
}
.payment-modal-add {
  margin: 24px 0 40px;
}

.delete-modal .modal-ge-icon i::before {
  color: #d92344;
}

.settings-modal-top {
  border-bottom: none;
  padding-bottom: 0px;
}
.settings-modal-title {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.settings-modal-title h3 {
  font-size: 12px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 0px;
}
.settings-modal-info {
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.settings-modal-info::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 100%;
  width: calc(100% + 48px);
  background-color: rgba(190, 165, 139, 0.1);
  border-radius: 8px;
}
.settings-modal-info-left {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}
.settings-modal-info-left h2 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 8px;
}
.settings-modal-info-left span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-modal-info-left span:not(:last-of-type) {
  margin-right: 24px;
}
.settings-modal-info-right {
  flex: 0 0 auto;
  margin-left: 16px;
}
.settings-modal-info-right span {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #191d26;
}
.settings-modal-details {
  position: relative;
  width: 100%;
  padding: 16px;
  border: 1px solid #ededed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.settings-modal-details-left {
  margin-right: 16px;
}
.settings-modal-details-right {
  width: 100%;
  overflow: hidden;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-modal-details-icon i {
  font-size: 32px;
}
.settings-modal-details-icon i::before {
  color: #191d26;
}
.settings-modal-details-img img {
  width: 56px;
  height: 56px;
  flex: 0 0 56px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
}
.settings-modal-details-content {
  position: relative;
  min-width: 0;
  overflow: hidden;
}
.settings-modal-details-content h3 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
  margin-bottom: 0px;
}
.settings-modal-details-content-wrap {
  display: flex;
  align-items: center;
}
.settings-modal-details-content-wrap p {
  font-size: 14px;
  font-weight: 300;
  color: #bea58b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 16px;
}
.settings-modal-details-content-wrap span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-modal-details-content-wrap span:not(:last-of-type) {
  margin-right: 24px;
}
.settings-modal-details-action {
  margin-left: 16px;
}
.settings-modal-details-action i {
  font-size: 32px;
}
.settings-modal-details-action i::before {
  color: #191d26;
}
.settings-modal-details-action a {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-modal-details-action a i {
  font-size: 16px;
}
.settings-modal-details-chat {
  margin-bottom: 40px;
}
.settings-modal-details-chat .settings-modal-details {
  margin-bottom: 15px;
}
.settings-modal-payment {
  position: relative;
  width: 100%;
  padding: 8px;
  border: 1px solid #ededed;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.settings-modal-payment-icon {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #26337A;
  border-radius: 5px;
  margin-right: 24px;
}
.settings-modal-payment-content {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}
.settings-modal-payment-content h3 {
  font-size: 14px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 0px;
}
.settings-modal-payment-content span {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-modal-payment-content span:not(:last-of-type) {
  margin-right: 24px;
}

.upload-contract-modal .modal-dialog {
  max-width: 750px;
}
.upload-contract-modal .upload-contract-wrap {
  padding: 0 40px;
}
.upload-contract-modal .upload-contract-top {
  position: relative;
  padding: 40px 0 24px;
  border-bottom: 1px solid #ededed;
}
.upload-contract-modal .upload-contract-top h1 {
  font-size: 18px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 16px;
}
.upload-contract-modal .upload-contract-top p {
  font-size: 14px;
  font-weight: 300;
  color: #5e6067;
}
.upload-contract-modal .upload-contract-top-wrap {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.upload-contract-modal .upload-contract-top-wrap span {
  font-size: 16px;
  font-weight: 300;
  color: #9d9d9e;
  margin-right: 30px;
}
.upload-contract-modal .upload-contract-top-wrap .wal-form-group {
  margin-bottom: 0px;
  width: 300px;
  margin-right: 8px;
}
.upload-contract-modal .upload-contract-top-wrap .button {
  width: auto;
  height: 40px;
  padding: 0 20px;
}
.upload-contract-modal .upload-contract-body {
  position: relative;
  padding: 8px 0;
}
.upload-contract-modal .upload-contract-body-wrap {
  padding-right: 16px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.upload-contract-modal .upload-contract-body-wrap::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.upload-contract-modal .upload-contract-body-wrap::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.upload-contract-modal .upload-contract-body-wrap::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.upload-contract-modal .upload-contract-body .contracts-item {
  margin-bottom: 8px;
}
.upload-contract-modal .upload-contract-body .contracts-item.selected {
  border-color: #bea58b;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.upload-contract-modal .upload-contract-body .wal-result-none-wrap {
  padding-top: 30px;
}
.upload-contract-modal .upload-contract-bottom {
  padding: 24px 0px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ededed;
}
.upload-contract-modal .upload-contract-bottom .button {
  width: 200px;
  max-width: 100%;
}
.upload-contract-modal .upload-contract-bottom .button:not(:last-of-type) {
  margin-right: 24px;
}
.upload-contract-modal .upload-contract-search {
  position: relative;
  display: flex;
  align-items: center;
}
.upload-contract-modal .upload-contract-close {
  position: absolute;
  font-size: 16px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.upload-contract-modal .upload-contract-close i::before {
  color: #191d26;
}

.currency-modal-body {
  padding: 40px 0;
  border-top: 1px solid #e4e4e4;
}
.currency-modal-item {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
}
.currency-modal-item:not(:last-of-type) {
  margin-bottom: 8px;
}
.currency-modal-item.active {
  border: 1px solid #bea58b;
  background-image: linear-gradient(360deg, rgba(190, 165, 139, 0.1) 0%, rgba(238, 232, 224, 0.1) 100%);
}
.currency-modal-img {
  margin-right: 16px;
}
.currency-modal-content h1 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #191d26;
  margin-bottom: 2px;
}
.currency-modal-content span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: rgba(25, 29, 38, 0.5);
}

.change-plan-modal .modal-dialog {
  max-width: 600px;
}
.change-plan-modal-top h1 {
  margin-bottom: 0px;
}

/* Tooltip Style
-------------------------------------------------------------- */
.info-tooltip {
  display: none;
  position: absolute;
  width: 230px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}
.info-tooltip-inner {
  background-color: #191d26;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.8);
  padding: 8px;
}
.info-tooltip::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -8px;
  border-left: 8px solid rgba(0, 0, 0, 0);
  border-right: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid #191d26;
}

/* React Dropdown Style
-------------------------------------------------------------- */
.css-1y2cgqh-control {
  min-height: 40px !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 8px !important;
  cursor: pointer !important;
}

.css-19wn1je-control {
  box-shadow: none !important;
  min-height: 40px !important;
  border: 1px solid #bea58b !important;
  border-radius: 8px !important;
  cursor: pointer !important;
}

.css-1uccc91-singleValue {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #191d26 !important;
}

.css-1wa3eu0-placeholder {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #9d9d9e !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: #e4e4e4 !important;
}

.css-tlfecz-indicatorContainer {
  color: #e4e4e4 !important;
}
.css-tlfecz-indicatorContainer:hover {
  color: #191d26;
}

.css-1rhbuit-multiValue {
  background-color: #f7f7f7 !important;
}

.css-12jo7m5 {
  color: #191d26 !important;
}

.css-xb97g8:hover {
  background-color: #fcf2f4 !important;
  color: #d92344 !important;
}

.css-26l3qy-menu {
  border-radius: 7px !important;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid #e6e6e6 !important;
}

.css-4ljt47-MenuList {
  scrollbar-color: #c1beb9 #f0f0f0 !important;
  scrollbar-width: thin !important;
}
.css-4ljt47-MenuList::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 2px !important;
}
.css-4ljt47-MenuList::-webkit-scrollbar-track {
  background: #f0f0f0 !important;
}
.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background: #c1beb9 !important;
}

.css-yt9ioa-option {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #9d9d9e !important;
  cursor: pointer !important;
}
.css-yt9ioa-option:active {
  background-color: #f7f7f7 !important;
}

.css-1n7v3ny-option {
  background-color: #f7f7f7 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #bea58b !important;
  cursor: pointer !important;
}
.css-1n7v3ny-option:active {
  background-color: #f7f7f7 !important;
}

.css-9gakcf-option {
  background-color: #f7f7f7 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #bea58b !important;
  cursor: pointer !important;
}
.css-9gakcf-option:active {
  background-color: #f7f7f7 !important;
}

.css-1gl4k7y {
  font-size: 14px !important;
  font-weight: 300 !important;
  columns: #9d9d9e !important;
}

/* Messages Style
-------------------------------------------------------------- */
.messages {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 100px;
}
.messages-wrap {
  padding: 30px 0;
}
.messages .more-options.show {
  z-index: 2;
}
.messages .more-options-icon a {
  display: flex;
  align-items: center;
}
.messages .more-options-icon a i::before {
  color: #191d26;
}
.messages .more-options-menu {
  min-width: 210px;
  padding: 8px 0;
}
.messages .more-options-menu li {
  padding: 8px 8px 8px 24px;
}
.messages .more-options-menu li a {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
}
.messages .more-options-menu li a i {
  font-size: 16px;
  border-radius: 0;
  border: none;
  padding: 0;
  margin-right: 8px;
}
.messages .more-options-menu li a.delete i::before {
  color: #d92344;
}
.messages-search {
  position: relative;
}
.messages-search .search-input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 300;
  color: #191d26;
  border: 1px solid #e4e4e4;
  padding-right: 40px;
  padding-left: 16px;
  transition: all 0.5s ease;
}
.messages-search .search-input::placeholder {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.messages-search .search-input:focus {
  outline: none;
  border: 1px solid #bea58b;
  transition: all 0.5s ease;
}
.messages-search i {
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.messages-search i::before {
  color: #191d26;
}
.messages-sidebar {
  position: relative;
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 10px;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.messages-sidebar-header {
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.messages-sidebar-search {
  padding: 16px 24px;
}
.messages-sidebar-body {
  position: relative;
  margin: 16px 8px 8px;
  padding-right: 8px;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
  overflow-y: auto;
  height: 100%;
}
.messages-sidebar-body::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.messages-sidebar-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.messages-sidebar-body::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.messages-sidebar .messages-list-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 20px 16px;
  cursor: pointer;
}
.messages-sidebar .messages-list-item.unread .messages-list-info h1, .messages-sidebar .messages-list-item.unread .messages-list-info p {
  font-weight: 700;
  color: #191d26;
}
.messages-sidebar .messages-list-item.unread .messages-list-info p i::before {
  color: #191d26;
}
.messages-sidebar .messages-list-item.open {
  background-color: #f1ece8;
}
.messages-sidebar .messages-list-item:not(:last-of-type) {
  margin-bottom: 8px;
}
.messages-sidebar .messages-list-item:hover {
  background-color: #f1ece8;
}
.messages-sidebar .messages-list-item:hover .messages-list-options {
  opacity: 1;
  visibility: visible;
  display: block;
}
.messages-sidebar .messages-list-item .muted {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.messages-sidebar .messages-list-item:last-of-type .messages-list-options .more-options-menu {
  top: auto;
  bottom: -8px;
}
.messages-sidebar .messages-list-item:nth-child(-n+3) .messages-list-options .more-options-menu {
  top: -8px;
  bottom: auto;
}
.messages-sidebar .messages-list-content {
  position: relative;
  width: 100%;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 1;
}
.messages-sidebar .messages-list-img {
  position: relative;
  margin-right: 16px;
}
.messages-sidebar .messages-list-img img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
}
.messages-sidebar .messages-list-img .status {
  position: absolute;
  top: 4px;
  right: 4px;
  border: 1px solid #ffffff;
}
.messages-sidebar .messages-list-info {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.messages-sidebar .messages-list-info h1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(25, 29, 38, 0.7);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.messages-sidebar .messages-list-info p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
}
.messages-sidebar .messages-list-info p i {
  margin-right: 4px;
}
.messages-sidebar .messages-list-title {
  display: flex;
  align-items: center;
}
.messages-sidebar .messages-list-title .highlight {
  font-weight: 500;
  color: #000000;
}
.messages-sidebar .messages-list-time {
  padding-left: 15px;
  display: flex;
}
.messages-sidebar .messages-list-time span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.messages-sidebar .messages-list-options {
  position: absolute;
  right: 0;
  top: 3px;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}
.messages-sidebar .messages-list-options .more-options-menu {
  top: -8px;
  right: -8px;
  z-index: -1;
}
.messages-box {
  position: relative;
  width: 100%;
  height: calc(100vh - 160px);
  border: 1px solid #ededed;
  border-radius: 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.messages-box-header {
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.messages-box-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
}
.messages-box-search {
  border-top: 1px solid #ededed;
  padding: 12px 40px;
  display: none;
}
.messages-box-user {
  display: flex;
  align-items: center;
}
.messages-box-user .avatar {
  position: relative;
  margin-right: 16px;
}
.messages-box-user .avatar img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.messages-box-user .info h5 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
  margin-bottom: 3px;
}
.messages-box-user .info .status {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.messages-box-action {
  position: relative;
  display: flex;
  align-items: center;
}
.messages-box-action div {
  display: flex;
  cursor: pointer;
}
.messages-box-action div:not(:last-of-type) {
  margin-right: 30px;
}
.messages-box-action .messages-search-icon i::before {
  color: #191d26;
}
.messages-box-action .messages-delete-icon i::before {
  color: #d92344;
}
.messages-box-action .messages-options .more-options-menu {
  top: -8px;
  right: -8px;
  z-index: -1;
}
.messages-box-body {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 8px 16px 0px;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.messages-box-body::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.messages-box-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.messages-box-body::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.messages-box-body-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.messages-box-footer {
  position: relative;
  width: 100%;
  padding: 16px 16px 16px 24px;
  border-top: 1px solid #ededed;
  margin-top: auto;
}
.messages-box-footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.messages-box-footer-wrap {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.messages-box-attachment {
  position: relative;
}
.messages-box-attachment .attachment-options {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 8px;
  font-size: 24px;
  color: #191d26;
  outline: none;
  background-color: transparent;
  padding: 0px;
}
.messages-box-attachment .attachment-options i::before {
  color: #191d26;
}
.messages-box-attachment .attachment-options:disabled {
  opacity: 0.5;
}
.messages-box-attachment .dropdown-attachment-menu {
  left: 0;
  min-width: 230px;
}
.messages-box-input {
  display: flex;
  flex: 1;
  margin: 0 16px 0 24px;
}
.messages-box-input .wal-form-control {
  height: 50px;
  background-color: #f7f7f7;
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
}
.messages-box-input .wal-form-control::placeholder {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.messages-box-input .wal-form-control:focus {
  background-color: #ffffff;
}
.messages-box-input .wal-form-control:disabled {
  opacity: 0.5;
}
.messages-box-input textarea.wal-form-control {
  min-height: 50px;
  padding: 10px 15px;
  resize: none;
  overflow: auto;
  white-space: normal;
}
.messages-box-send .button {
  padding: 0 50px;
}
.messages-box-send .button:disabled {
  opacity: 0.5;
}
.messages-box-request {
  margin-top: 100px;
  text-align: center;
  padding: 0 15px;
}
.messages-box-request h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 16px;
}
.messages-box-request h1 span {
  font-weight: 500;
}
.messages-box-request p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.messages-box-request-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 64px;
}
.messages-box-request-buttons .button {
  width: 200px;
  margin-bottom: 16px;
}
.messages-item {
  margin-bottom: 16px;
}
.messages-item-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}
.messages-item .messages-content {
  position: relative;
  max-width: 40%;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
}
.messages-item .messages-content p {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
}
.messages-item .messages-content p .highlight {
  font-weight: 700;
}
.messages-item .messages-content .animate-typing .dot {
  background-color: #191d26;
}
.messages-item .messages-time {
  margin-left: 16px;
}
.messages-item .messages-time span {
  font-size: 12px;
  font-size: 300;
  color: #9d9d9e;
}
.messages-item .messages-image {
  position: relative;
}
.messages-item .messages-image img {
  width: 210px;
  max-width: 100%;
  height: 187px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 10px;
}
.messages-item .messages-location {
  padding: 8px;
  background-color: #f7f7f7;
  height: 250px;
  width: 50%;
  max-width: 100%;
  border-radius: 10px;
}
.messages-item .messages-location-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.messages-item .messages-location-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.messages-item .messages-location-wrap #map {
  height: 100%;
  width: 100%;
}
.messages-item .messages-location-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 8px 16px;
}
.messages-item .messages-location-bottom::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
.messages-item .messages-location-bottom .time {
  position: relative;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}
.messages-item .messages-location-bottom a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.messages-item .messages-location-bottom a i {
  font-size: 16px;
  margin-left: 8px;
}
.messages-item .messages-location-bottom a i::before {
  color: #ffffff;
}
.messages-item .messages-file {
  position: relative;
  max-width: 40%;
  padding: 8px;
  background-color: rgb(247, 247, 247);
  border-radius: 10px;
  overflow: hidden;
}
.messages-item .messages-file-wrap {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px 16px;
}
.messages-item .messages-file-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  overflow: hidden;
}
.messages-item .messages-file-info {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.messages-item .messages-file-info h2 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
}
.messages-item .messages-file-info span {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.messages-item .messages-file-icon {
  font-size: 32px;
  margin-right: 16px;
}
.messages-item .messages-file-icon i::before {
  color: #191d26;
}
.messages-item .messages-file-download {
  margin-left: 16px;
}
.messages-item .messages-file-download a i::before {
  color: #191d26;
}
.messages-item-action {
  position: relative;
  margin-left: 16px;
}
.messages-item-action .more-options-menu {
  top: -8px;
  left: -8px;
  min-width: 160px;
  z-index: -1;
  padding-top: 16px;
}
.messages-item.messages-not-delivered .error {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #d92344;
  margin-top: 4px;
}
.messages-item.messages-not-delivered .more-options-icon a i::before {
  color: #d92344;
}
.messages-item.self .messages-item-wrap {
  flex-direction: row-reverse;
}
.messages-item.self .messages-content {
  background-color: #bea58b;
}
.messages-item.self .messages-content p {
  color: #ffffff;
}
.messages-item.self .messages-time {
  margin-left: 0px;
  margin-right: 16px;
}
.messages-item.self .messages-location {
  background-color: #bea58b;
}
.messages-item.self .messages-item-action {
  margin-left: 0px;
  margin-right: 16px;
}
.messages-item.self .messages-item-action .more-options-menu {
  left: auto;
  right: -8px;
}
.messages-item.self .messages-file {
  background-color: #bea58b;
}
.messages-item.self.messages-not-delivered .error {
  text-align: right;
}
.messages-item:last-of-type .messages-item-action .more-options-menu {
  top: auto;
  bottom: -8px;
}
.messages .messages-line {
  position: relative;
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages .messages-line::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
}
.messages .messages-line .messages-line-wrap {
  padding: 0 23px;
  background-color: #ffffff;
  z-index: 1;
}
.messages .messages-line .messages-line-wrap span {
  padding: 4px 35px;
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
  border-radius: 12px;
}
.messages-empty {
  margin-top: 120px;
  padding: 0 45px;
}
.messages-blocked {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #ededed;
}
.messages-blocked-title {
  display: flex;
  align-items: center;
}
.messages-blocked-title span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-left: 8px;
}
.messages-blocked-title i::before {
  color: #9d9d9e;
}
.messages-blocked a {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #9d9d9e;
}
.messages-blocked-footer {
  position: relative;
  padding: 24px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.messages-blocked-footer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
}
.messages-blocked-content {
  text-align: center;
}
.messages-blocked-content h2 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 16px;
}
.messages-blocked-content p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.messages-blocked-buttons {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.messages-blocked-buttons .button:not(:last-of-type) {
  margin-right: 32px;
}
.messages-contracts {
  position: absolute;
  top: 81px;
  left: 0;
  padding: 24px 40px 32px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.6), rgb(255, 255, 255));
  z-index: 1;
  width: 100%;
}
.messages-contracts .contracts-item {
  background-color: #ffffff;
  border: 1px solid #bea58b;
}
.messages-contracts .contracts-center {
  margin-right: 0px;
}
.messages-contracts .contracts-content-wrap {
  margin-right: 0px;
}
.messages-contracts .contracts-icon {
  margin-left: 16px;
}
.messages-contracts .contracts-title h1 {
  margin-bottom: 0px;
}
.messages-error {
  position: absolute;
  top: 81px;
  left: 0;
  padding: 24px 40px 32px;
  width: 100%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.6), rgb(255, 255, 255));
  z-index: 1;
}
.messages-error p {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  padding: 16px;
  border: 1px solid #d92344;
  border-radius: 7px;
  background-color: #ffffff;
}
.messages-error p i {
  font-size: 32px;
  margin-right: 16px;
}
.messages-error p i::before {
  color: #d92344;
}

.animate-typing .dot {
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  margin-left: 3px;
  background: #9d9d9e;
  animation: wave 1.3s linear infinite;
}
.animate-typing .dot:nth-child(2) {
  animation-delay: -1.1s;
}
.animate-typing .dot:nth-child(3) {
  animation-delay: -0.9s;
}

.incoming-calls-modal {
  background-color: rgba(25, 29, 38, 0.6);
}
.incoming-calls-modal .modal-dialog {
  max-width: 750px;
}
.incoming-calls-modal .modal-content {
  padding: 0 40px;
  border: none;
  border-radius: 15px;
}
.incoming-calls-modal-header {
  position: relative;
  padding: 24px 0;
}
.incoming-calls-modal-header h1 {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
  display: flex;
  align-items: center;
}
.incoming-calls-modal-header h1 i {
  margin-right: 16px;
}
.incoming-calls-modal-header h1 i::before {
  color: #191d26;
}
.incoming-calls-modal-header p {
  font-size: 16px;
  font-weight: 300;
  color: #5e6067;
  margin-top: 24px;
}
.incoming-calls-modal-body {
  padding: 24px 0;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.incoming-calls-modal-footer {
  padding: 40px 0;
  display: flex;
  justify-content: flex-end;
}
.incoming-calls-modal-footer .icon {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
}
.incoming-calls-modal-footer .icon i::before {
  color: #191d26;
}
.incoming-calls-modal-footer .button {
  margin-left: 24px;
  width: 200px;
}
.incoming-calls-modal-close {
  position: absolute;
  top: 24px;
  right: 40px;
}
.incoming-calls-modal-close a {
  font-size: 24px;
  display: flex;
  justify-content: center;
}
.incoming-calls-modal-close a i::before {
  color: #191d26;
}
.incoming-calls-profile {
  display: flex;
  align-items: center;
}
.incoming-calls-profile-img {
  flex: 0 0 64px;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}
.incoming-calls-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.incoming-calls-profile-content h2 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
  margin-bottom: 8px;
}
.incoming-calls-profile-content span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}

.video-calls-modal {
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
}
.video-calls-modal .modal-dialog {
  max-width: 100%;
  overflow: hidden;
}
.video-calls-modal .modal-content {
  border: none;
  height: 100%;
  width: 100%;
  position: absolute;
  min-height: calc(100vh - 150px);
  overflow: hidden;
}
.video-calls-modal .calls-wrap {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 40px 15px;
  justify-content: flex-end;
  align-items: center;
}
.video-calls-modal .calls-wrap::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 29, 38, 0.95);
}
.video-calls-modal .calls-wrap .info {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.video-calls-modal .calls-wrap.connecting .calls-close {
  top: 44px;
}
.video-calls-modal .calls-top {
  margin-bottom: 150px;
  -webkit-user-select: none;
          user-select: none;
}
.video-calls-modal .calls-avatar {
  position: relative;
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 8px;
}
.video-calls-modal .calls-avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-calls-modal .calls-info {
  position: relative;
  text-align: center;
}
.video-calls-modal .calls-info h2 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 8px;
}
.video-calls-modal .calls-info span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
}
.video-calls-modal .calls-info span.on-call {
  color: #bea58b;
}
.video-calls-modal .calls-info span.muted {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.video-calls-modal .calls-info span.muted i {
  margin-right: 8px;
}
.video-calls-modal .calls-info span.muted i::before {
  color: #ffffff;
}
.video-calls-modal .calls-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.video-calls-modal .calls-action {
  display: flex;
  align-items: center;
  z-index: 1;
}
.video-calls-modal .calls-action .icon {
  width: 40px;
  height: 40px;
  flex: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid;
  border-color: rgba(228, 228, 228, 0.1);
  border-radius: 7px;
  -webkit-user-select: none;
          user-select: none;
}
.video-calls-modal .calls-action .icon i::before {
  color: #ffffff;
}
.video-calls-modal .calls-action .icon:not(:last-of-type) {
  margin-right: 16px;
}
.video-calls-modal .calls-action .icon:focus {
  outline: none;
}
.video-calls-modal .calls-action .icon.disable {
  border-color: rgba(228, 228, 228, 0.04);
}
.video-calls-modal .calls-action .icon.disable i {
  opacity: 0.4;
}
.video-calls-modal .calls-action .icon.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(228, 228, 228, 0.4);
}
.video-calls-modal .calls-action .icon.active i {
  opacity: 0.5;
}
.video-calls-modal .calls-action .icon.close-call {
  border-color: #d92344;
  background-color: rgba(217, 35, 68, 0.2);
}
.video-calls-modal .calls-action .icon.close-call i::before {
  color: #d92344;
}
.video-calls-modal .calls-end {
  margin-top: 16px;
}
.video-calls-modal .calls-end .button {
  height: 40px;
  background-color: rgba(217, 35, 68, 0.2);
  color: #d92344;
  border-radius: 8px;
  border: none;
}
.video-calls-modal .calls-close {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}
.video-calls-modal .calls-close i::before {
  color: #ffffff;
}
.video-calls-modal-wrap {
  justify-content: space-between;
}
.video-calls-modal .info {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  z-index: 2;
}
.video-calls-modal .info i {
  margin-right: 10px;
  font-size: 16px;
}
.video-calls-modal .info.danger {
  background-color: #d92344;
}
.video-calls-wrap .video-call {
  background-color: #191d26;
  border-radius: 10px;
}
.video-calls-wrap .video-call video {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transform: scaleX(-1);
}
.video-calls-wrap .video-call img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.video-calls-wrap .video-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0E1012;
}
.video-calls-wrap .video-overlay i {
  opacity: 0.8;
}
.video-calls-wrap .video-overlay i::before {
  color: #ffffff;
}
.video-calls-wrap .video-large {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-calls-wrap .video-large .video-overlay i {
  font-size: 71px;
}
.video-calls-wrap .video-small {
  position: absolute;
  bottom: 24px;
  right: 24px;
  height: 180px;
  width: 145px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
}
.video-calls-wrap .video-small .video-overlay i {
  font-size: 24px;
}
.video-calls-wrap .video-small-profile {
  height: 64px;
  width: 64px;
  overflow: hidden;
  border-radius: 50%;
}
.video-calls-wrap .video-small-profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-calls-wrap .video-small.video-disabled {
  border: 1px solid #e4e4e4;
}
.video-calls-wrap:hover .video-calls-bottom {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}
.video-calls-wrap .muted {
  display: flex;
  align-items: center;
}
.video-calls-top {
  position: relative;
  width: 100%;
  padding: 16px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(24, 28, 36, 0) 0%, rgba(24, 28, 36, 0.9) 100%);
  z-index: 1;
}
.video-calls-top .action {
  display: flex;
  align-items: center;
}
.video-calls-top .action .icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}
.video-calls-top .action .icon i::before {
  color: #ffffff;
}
.video-calls-top .action .icon:not(:last-of-type) {
  margin-right: 40px;
}
.video-calls-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 24px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}
.video-calls-bottom::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 180px;
  width: 100%;
  background: linear-gradient(180deg, rgba(24, 28, 36, 0) 0%, rgb(24, 28, 36) 100%);
}
.video-calls-bottom span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #bea58b;
  margin-bottom: 16px;
  z-index: 1;
  -webkit-user-select: none;
          user-select: none;
}
.video-calls-profile {
  display: flex;
  align-items: center;
}
.video-calls-profile-img {
  height: 30px;
  width: 30px;
  flex: 0 0 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
}
.video-calls-profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-calls-profile-content h1 {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.video-calls-profile-content h1 .muted {
  margin-left: 16px;
}

.end-calls-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.end-calls-img {
  height: 80px;
  width: 80px;
  flex: 0 0 80px;
  border-radius: 50%;
  overflow: hidden;
}
.end-calls-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.end-calls-info {
  margin-top: 16px;
  text-align: center;
}
.end-calls-info h1 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.end-calls-info span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #bea58b;
  margin-top: 8px;
}

.no-camera-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.no-camera-found i {
  font-size: 17px;
  opacity: 0.64;
  margin-right: 8px;
}
.no-camera-found i::before {
  color: #ffffff;
}
.no-camera-found span {
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  opacity: 0.8;
}

/* Notifications Style
--------------------------------------------------------------*/
.notifications {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 180px;
}
.notifications-list {
  position: relative;
}
.notifications-item {
  position: relative;
  width: 100%;
  padding: 16px 24px;
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notifications-item:not(:last-of-type) {
  margin-bottom: 6px;
}
.notifications-item-left {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.notifications-item-img {
  width: 56px;
  height: 56px;
  margin-right: 22px;
  overflow: hidden;
  border-radius: 50%;
  flex: 0 0 56px;
}
.notifications-item-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.notifications-item-content h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.notifications-item-content h1 span {
  font-weight: 500;
}
.notifications-item-info {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.notifications-item-info p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.notifications-item-info p:not(:last-of-type) {
  margin-right: 16px;
}
.notifications-item-info p.time {
  flex: 0 0 auto;
}
.notifications-item-right {
  display: flex;
  align-items: center;
}
.notifications-item-buttons {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.notifications-item-buttons .button {
  width: 130px;
  height: 40px;
}
.notifications-item-buttons .button:not(:last-of-type) {
  margin-right: 24px;
}
.notifications-item-delete a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid;
  border-color: rgba(217, 35, 68, 0.1);
  border-radius: 8px;
  transition: all 0.5s ease;
}
.notifications-item-delete a i::before {
  color: #d92344;
  transition: all 0.5s ease;
}
.notifications-item-delete a:hover {
  background-color: #d92344;
  border-color: #d92344;
  transition: all 0.5s ease;
}
.notifications-item-delete a:hover i::before {
  color: #ffffff;
  transition: all 0.5s ease;
}
.notifications-line {
  position: relative;
  padding: 0 0 8px 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
}
.notifications-line span {
  padding-left: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #191d26;
  text-transform: uppercase;
}

/* Home Page Style
-------------------------------------------------------------- */
.hero {
  position: relative;
  width: 100%;
  float: left;
  height: 800px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 167px !important;
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 29, 38, 0.95);
}
.hero-content h1 {
  position: relative;
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 65px;
}
.hero-content h1::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -40px;
  height: 1px;
  width: 120px;
  background-color: #bea58b;
}
.hero-content p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 50px;
}
.hero-img {
  position: absolute;
  right: 0;
  width: 787px;
  height: 637px;
}
.hero-img img {
  width: 787px;
  height: 637px;
}
.hero-button {
  display: flex;
  align-items: center;
}
.hero-button .button {
  width: 200px;
}
.hero-button .button:first-child {
  margin-right: 24px;
}
.hero-lawyers {
  text-align: center;
  position: relative;
}
.hero-lawyers span {
  font-size: 80px;
  font-weight: 500;
  color: #ffffff;
}
.hero-lawyers p {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

.rated-lawyers {
  position: relative;
  width: 100%;
  float: left;
}
.rated-lawyers-wrap {
  position: relative;
  margin: 0 -12px;
}
.rated-lawyers-item {
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: 210px;
  overflow: hidden;
  margin: 0 12px;
}
.rated-lawyers-item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
}
.rated-lawyers-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.rated-lawyers-content {
  width: 100%;
  position: absolute;
  padding: 0 8px;
  left: 0px;
  bottom: 16px;
}
.rated-lawyers-content h1 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 8px;
}
.rated-lawyers-bottom {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rated-lawyers-bottom span {
  margin-left: 24px;
}
.rated-lawyers-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}
.rated-lawyers-controls button {
  border-radius: 0;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 6px;
  height: 16px;
  margin: 0 20px;
}
.rated-lawyers-controls button i::before {
  color: #9d9d9e;
}
.rated-lawyers-controls button:focus {
  outline: none;
}
.rated-lawyers-controls button:focus i::before {
  color: #191d26;
}
.rated-lawfirms-wrap {
  position: relative;
  margin: 0 -12px;
}
.rated-lawfirms-wrap .slick-list {
  overflow: visible;
}
.rated-lawfirms-item {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 4px;
  background-color: rgba(247, 247, 247, 0.9);
  border-radius: 8px;
  margin: 0 16px;
}
.rated-lawfirms-img {
  width: 100%;
  height: 96px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.rated-lawfirms-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.rated-lawfirms-content {
  padding: 8px;
}
.rated-lawfirms-content h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rated-lawfirms-content .rated-stars {
  margin-top: 8px;
}
.rated-stars {
  display: flex;
}
.rated-stars i {
  margin-right: 4px;
  cursor: pointer;
}
.rated-stars i.icon-star::before {
  color: #e5e5e5;
}

.download-app {
  position: relative;
  width: 100%;
  float: left;
}
.download-app-wrap {
  background-color: #191d26;
  border-radius: 20px;
  padding: 40px 65px 65px;
}
.download-app-img {
  position: absolute;
  bottom: -65px;
}
.download-app-content img {
  margin-bottom: 57px;
}
.download-app-content h1 {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 24px;
}
.download-app-content p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.download-app-content p:not(:last-of-type) {
  margin-bottom: 15px;
}
.download-app-buttons {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.download-app-buttons .button {
  width: 200px;
}
.download-app-buttons a:not(:last-of-type) {
  margin-right: 24px;
}

/* Lawyers Page Style
-------------------------------------------------------------- */
.lawyers-region {
  height: 512px;
  padding: 0px;
  border: none;
}
.lawyers-region-map {
  position: relative;
  height: 512px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.lawyers-region-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lawyers-region-map-button {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 16px;
  text-align: center;
  width: 100%;
}
.lawyers-region-map-button .button {
  position: relative;
  width: 220px;
  margin: 0 auto;
}
.lawyers-region-map-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 120px;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.lawyers-region-map-disabled {
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.lawyers-region .wal-results-wrap {
  height: 100%;
}
.lawyers-item {
  position: relative;
  width: 100%;
  padding: 20px 24px;
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.lawyers-item:not(:last-of-type) {
  margin-bottom: 8px;
}
.lawyers-item-region {
  padding: 12px 16px;
}
.lawyers-item-region .lawyers-bookmarks {
  right: 16px;
}
.lawyers-item-region-disabled {
  width: 100%;
  height: 96px;
}
.lawyers-img {
  position: relative;
  margin-right: 24px;
}
.lawyers-img img {
  height: 56px;
  width: 56px;
  object-fit: cover;
  border-radius: 50%;
}
.lawyers-img .status {
  position: absolute;
  top: 4px;
  right: 4px;
}
.lawyers-img-alt img {
  border-radius: 10px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}
.lawyers-content {
  min-width: 0;
}
.lawyers-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.lawyers-title-wrap {
  display: flex;
  align-items: center;
}
.lawyers-title h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.lawyers-type {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.lawyers-type span {
  display: flex;
  align-items: center;
}
.lawyers-type span i {
  font-size: 16px;
}
.lawyers-type span:not(:last-of-type) {
  margin-right: 10px;
}
.lawyers-type-tooltip {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  padding: 8px 16px;
  background-color: rgba(25, 29, 38, 0.85);
  border-radius: 10px;
  width: 180px;
  z-index: 1;
  display: none;
  visibility: hidden;
  opacity: 0;
}
.lawyers-type-tooltip-wrap ul li {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
}
.lawyers-type-tooltip-wrap ul li:not(:last-child) {
  margin-bottom: 8px;
}
.lawyers-info {
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 0;
}
.lawyers-info span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-left: 24px;
}
.lawyers-info span.type {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bea58b;
}
.lawyers-info span i {
  margin-right: 8px;
}
.lawyers-info-region {
  flex-direction: column;
  align-items: flex-start;
}
.lawyers-info-region-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}
.lawyers-info-region-bottom span {
  margin-left: 0px;
}
.lawyers-info-region-bottom span:not(:last-of-type) {
  margin-right: 24px;
}
.lawyers-info-region-bottom span.type {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lawyers-bookmarks {
  position: absolute;
  top: 16px;
  right: 24px;
  display: flex;
  cursor: pointer;
}
.lawyers-bookmarks i::before {
  color: #191d26;
}

.verify-badge {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.verify-badge img {
  width: 16px;
  height: 16px;
}
.verify-badge-tooltip {
  display: none;
  position: absolute;
  width: 150px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(25, 29, 38, 0.85);
  z-index: 1;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 8px 16px;
  text-align: center;
}
.verify-badge-tooltip-inner {
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
}
.verify-badge:hover .verify-badge-tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.lawfirm-profile-info .profile-info-top .lawyers-type {
  margin-left: 0px;
}
.lawfirm-offices-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  gap: 10px;
}
.lawfirm-offices-item:not(:last-of-type) {
  margin-bottom: 8px;
}
.lawfirm-offices-item span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: italic;
  color: #191d26;
}
.lawfirm-offices-item a {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  display: flex;
  align-items: center;
}
.lawfirm-offices-item a i {
  margin-left: 8px;
}

/* Contracts Page Style
-------------------------------------------------------------- */
.contracts {
  position: relative;
  width: 100%;
  float: left;
}
.contracts-item {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  border: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 16px;
}
.contracts-item-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-width: 0;
}
.contracts-item-package {
  border-color: #D9E1F8;
}
.contracts-item-package::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.03) 0%, rgba(101, 114, 152, 0.15) 100%);
}
.contracts-left, .contracts-right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.contracts-img {
  height: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  margin-right: 15px;
  font-size: 32px;
}
.contracts-img img {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.contracts-img i::before {
  color: #191d26;
}
.contracts-icon {
  margin-right: 15px;
}
.contracts-icon i {
  font-size: 32px;
}
.contracts-icon i::before {
  color: #191d26;
}
.contracts-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin-right: 20px;
  min-width: 0;
}
.contracts-content {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.contracts-content-wrap {
  width: 100%;
  min-width: 0;
  overflow: hidden;
}
.contracts-title h1 {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-bottom: 6px;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contracts-info {
  display: flex;
  align-items: center;
}
.contracts-info p {
  font-size: 14px;
  font-weight: 400;
  color: #bea58b;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 24px;
}
.contracts-info span {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9e;
  margin-right: 24px;
  flex-shrink: 0;
}
.contracts-info .rated-stars {
  margin-right: 16px;
}
.contracts-bookmark {
  position: relative;
  margin-right: 24px;
}
.contracts-bookmark a {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.contracts-bookmark i {
  font-size: 16px;
}
.contracts-bookmark i::before {
  color: #191d26;
}
.contracts-share {
  margin-right: 24px;
}
.contracts-share a {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.contracts-view {
  margin-right: 24px;
}
.contracts-view a {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
}
.contracts-purchase {
  position: relative;
  height: 100%;
  padding: 0 16px;
  background-color: #191d26;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contracts-purchase i {
  font-size: 24px;
}
.contracts-purchase i::before {
  color: #ffffff;
}
.contracts-packages-slide {
  position: relative;
  padding: 16px;
  background: linear-gradient(to top, rgba(168, 169, 200, 0.15) 0%, rgba(101, 114, 152, 0.15) 100%);
  margin-bottom: 40px;
  overflow: hidden;
}
.contracts-packages-slide-wrap {
  display: flex;
}
.contracts-packages-slide .item {
  margin: 0 4px;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.contracts-packages-slide .item i {
  margin-right: 4px;
}
.contracts-packages-slide .item i::before {
  color: #929EBF;
}
.contracts-packages-slide .item span {
  font-size: 12px;
  font-weight: 300;
  color: #929EBF;
}
.contracts-packages-slide .packages-button-prev, .contracts-packages-slide .packages-button-next {
  width: 50px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.contracts-packages-slide .packages-button-prev i, .contracts-packages-slide .packages-button-next i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.contracts-packages-slide .packages-button-prev {
  left: 0;
  background: linear-gradient(to left, rgba(242, 242, 247, 0) 0%, rgb(242, 242, 247) 100%);
}
.contracts-packages-slide .packages-button-prev i {
  left: 6px;
  z-index: 1;
}
.contracts-packages-slide .packages-button-prev i::before {
  color: #191d26;
}
.contracts-packages-slide .packages-button-next {
  right: 0;
  background: linear-gradient(to right, rgba(242, 242, 247, 0) 0%, rgb(242, 242, 247) 100%);
}
.contracts-packages-slide .packages-button-next i {
  right: 6px;
}
.contracts-packages-slide .packages-button-next i::before {
  color: #191d26;
}

/* Courts Page Style
-------------------------------------------------------------- */
.court-section {
  position: relative;
  width: 100%;
  float: left;
}
.court-section-content h1 {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
  margin-bottom: 32px;
}
.court-section-content p {
  font-size: 16px;
  font-weight: 400;
  color: #5e6067;
  margin-bottom: 60px;
}
.court-section-content-white h1 {
  color: #ffffff;
}
.court-section-content-white p {
  color: rgba(255, 255, 255, 0.6);
}
.court-section-two {
  padding: 20px 0;
  background-color: #191d26;
}

.courts {
  position: relative;
  width: 100%;
  float: left;
}
.courts-group-add {
  display: flex;
  justify-content: center;
  align-items: center;
}
.courts-group-add .button {
  width: 190px;
  max-width: 100%;
}

/* Knowledge Page Style
-------------------------------------------------------------- */
.knowledge-item {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 24px;
}
.knowledge-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.knowledge-item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
}
.knowledge-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  bottom: 8px;
  left: 0;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
}
.knowledge-content span.tag {
  font-size: 10px;
  font-weight: 400;
  color: #bea58b;
  text-transform: uppercase;
  margin-bottom: 8px;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.knowledge-content h1 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 8px;
}
.knowledge-content-bottom {
  display: flex;
  align-items: center;
}
.knowledge-content-bottom span {
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 8px;
}
.knowledge-content-bottom span i {
  font-size: 16px;
  margin-right: 8px;
}
.knowledge-content-bottom span.author {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.knowledge-content-bottom span.views {
  display: flex;
  flex: 1;
  align-items: center;
  white-space: nowrap;
}
.knowledge-slider {
  position: relative;
  width: 100%;
  float: left;
  background-color: #f7f7f7;
}
.knowledge-slider-wrap {
  padding: 40px 0;
}
.knowledge-slider-items {
  margin: 0 -12px;
}
.knowledge-slider-items .slick-list {
  overflow: visible;
}
.knowledge-slider-items .knowledge-item {
  margin: 0 12px;
}
.knowledge-item-list {
  position: relative;
  margin-bottom: 8px;
}
.knowledge-item-list-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 7px;
  border: 1px solid #ededed;
  transition: all 0.5s ease;
}
.knowledge-item-list-wrap:hover {
  border-color: #bea58b;
}
.knowledge-item-list-wrap:hover .knowledge-item-list-view i::before {
  transition: all 0.5s ease;
  color: #191d26;
}
.knowledge-item-list-left {
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 0;
  width: 100%;
}
.knowledge-item-list-img {
  position: relative;
  margin-right: 20px;
}
.knowledge-item-list-img img {
  width: 80px;
  height: 65px;
  object-fit: cover;
  border-radius: 8px;
}
.knowledge-item-list-content {
  min-width: 0;
  overflow: hidden;
}
.knowledge-item-list-title h1 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.knowledge-item-list-info {
  display: flex;
  flex-wrap: wrap;
}
.knowledge-item-list-info span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.knowledge-item-list-info span:not(:last-of-type) {
  margin-right: 16px;
}
.knowledge-item-list-info span.type {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bea58b;
}
.knowledge-item-list-view {
  padding: 0 15px;
  font-size: 24px;
}

.articles-tags ul li {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 8px;
}
.articles-tags ul li a {
  display: block;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(230, 230, 230, 0.1);
  border-radius: 5px;
  text-transform: uppercase;
}
.articles-content {
  margin-bottom: 64px;
}
.articles-content p {
  margin-bottom: 16px;
}
.articles-contracts {
  margin-bottom: 56px;
}
.articles-contracts .contracts-item {
  margin-bottom: 8px;
}

/* Settings Page Style
-------------------------------------------------------------- */
.settings {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 180px;
}
.settings-top {
  margin-bottom: 32px;
}
.settings-top-title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
}
.settings-sidebar {
  position: relative;
  width: 100%;
  height: 800px;
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 30px 16px 16px;
  display: flex;
  flex-direction: column;
}
.settings-sidebar .settings-menu {
  width: 100%;
}
.settings-sidebar .settings-menu ul {
  margin-bottom: 60px;
}
.settings-sidebar .settings-menu ul li {
  position: relative;
}
.settings-sidebar .settings-menu ul li:not(:last-child) {
  margin-bottom: 30px;
}
.settings-sidebar .settings-menu ul li a {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.6;
  transition: all 0.5s ease;
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-sidebar .settings-menu ul li a div {
  display: flex;
  align-items: center;
}
.settings-sidebar .settings-menu ul li a div i {
  font-size: 24px;
  margin-right: 16px;
}
.settings-sidebar .settings-menu ul li a div i::before {
  color: #191d26;
}
.settings-sidebar .settings-menu ul li.active a {
  opacity: 1;
}
.settings-sidebar .settings-menu ul li.active a::before {
  content: "";
  position: absolute;
  top: 0;
  left: -16px;
  height: 100%;
  width: 2px;
  background-color: #bea58b;
}
.settings-sidebar .settings-menu ul li:hover a {
  opacity: 1;
  transition: all 0.5s ease;
}
.settings-sidebar .settings-logout {
  width: 100%;
  margin-top: auto;
}
.settings-sidebar .settings-logout .button-danger {
  background-color: rgba(217, 35, 68, 0.05);
  border: none;
  color: #d92344 !important;
}
.settings-sidebar .settings-logout .button-danger i::before {
  color: #d92344;
}
.settings-sidebar-icon {
  position: relative;
  display: none;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.settings-sidebar-icon-wrap {
  display: flex;
  align-items: center;
}
.settings-sidebar-icon-wrap i {
  font-size: 24px;
}
.settings-sidebar-icon-wrap span {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
  margin-left: 8px;
}
.settings-sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  background-color: rgba(25, 29, 38, 0.5);
  opacity: 0;
  visibility: hidden;
  cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='30px' height='30px' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512.001 512.001;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23fff' d='M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"), pointer;
}
.settings-sidebar-overlay.show {
  opacity: 1;
  visibility: visible;
}
.settings-content {
  position: relative;
  width: 100%;
  height: 800px;
  border: 1px solid #ededed;
  border-radius: 10px;
}
.settings-content .wal-content-head {
  padding: 0 40px;
  margin-bottom: 0px;
}
.settings-content .wal-content-head-wrap {
  padding-bottom: 24px;
  border-bottom: 1px solid #ededed;
}
.settings-content .accordion-head {
  padding: 0 40px;
}
.settings-content .accordion-head-wrap {
  padding: 40px;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.settings-content .accordion-head-wrap[aria-expanded=true] i {
  transition: transform 0.3s ease;
  transform: rotate(90deg);
}
.settings-content .accordion-head h2 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-content .accordion-head i {
  font-size: 16px;
  transition: transform 0.3s ease;
}
.settings-content .accordion-head i::before {
  color: #191d26;
}
.settings-content .accordion-body {
  padding: 0 40px;
  background-color: rgba(240, 240, 240, 0.2);
}
.settings-content .accordion-body-wrap {
  padding: 40px;
  border-bottom: 1px solid #ededed;
}
.settings-content .accordion-body-wrap p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-content .accordion-body-wrap .wal-result-none-wrap p {
  margin-bottom: 0px;
}
.settings-tab {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  padding: 0 40px;
}
.settings-tab-wrap {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  border-bottom: 1px solid #ededed;
}
.settings-tab-wrap::-webkit-scrollbar {
  height: 4px;
  border-radius: 2px;
}
.settings-tab-wrap::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-tab-wrap::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-tab-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  flex: 0 0 25%;
}
.settings-tab-item-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px 0;
  opacity: 0.5;
  transition: all 0.5s ease;
}
.settings-tab-item-wrap span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-tab-item:not(:last-of-type)::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 40px;
  background-color: #e4e4e4;
}
.settings-tab-item-info {
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
}
.settings-tab-item-info i {
  font-size: 48px;
}
.settings-tab-item-info i::before {
  color: #191d26;
}
.settings-tab-item-info p {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
  margin-left: 24px;
}
.settings-tab-item.active .settings-tab-item-wrap {
  opacity: 1;
}
.settings-tab-item.active .settings-tab-item-wrap::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #bea58b;
}
.settings-tab-item:hover .settings-tab-item-wrap {
  opacity: 1;
  transition: all 0.5s ease;
}
.settings-history-list {
  position: relative;
  width: 100%;
  height: calc(100% - 230px);
  max-height: 100%;
  padding: 16px 8px 16px 40px;
  background-color: rgba(237, 237, 237, 0.2);
}
.settings-history-list-wrap {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;
}
.settings-history-list-wrap::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-history-list-wrap::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-history-list-wrap::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-history-item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #ededed;
  background-color: #ffffff;
  padding: 18px 24px;
  margin-bottom: 8px;
}
.settings-history-item-left {
  position: relative;
  display: flex;
  align-items: center;
}
.settings-history-item-icon {
  margin-right: 24px;
}
.settings-history-item-icon i {
  font-size: 32px;
}
.settings-history-item-icon i::before {
  color: #191d26;
}
.settings-history-item-content h3 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-history-item-info {
  display: flex;
  align-items: center;
  margin-top: 4px;
  flex-wrap: wrap;
}
.settings-history-item-info span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-history-item-info span:not(:last-of-type) {
  margin-right: 24px;
}
.settings-history-item-right {
  position: relative;
  display: flex;
  align-items: center;
}
.settings-history-item-price span {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-history-item-action {
  display: flex;
  align-items: center;
}
.settings-history-item-action .wal-icons-button {
  height: 40px;
  width: 40px;
  border-color: #ededed;
  font-size: 16px;
  background-color: #ffffff;
}
.settings-history-item-action .wal-icons-button i::before {
  color: #191d26;
}
.settings-history-item-action .wal-icons-button.delete {
  border-color: rgba(217, 35, 68, 0.1);
}
.settings-history-item-action .wal-icons-button.delete i::before {
  color: #d92344;
}
.settings-edit-profile {
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.settings-edit-profile::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-edit-profile::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-edit-profile::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-edit-profile-top {
  padding: 32px 40px;
  background-color: rgba(240, 240, 240, 0.2);
}
.settings-edit-profile-top .wal-form-profile::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ededed;
}
.settings-edit-profile-body {
  padding: 0 40px;
}
.settings-edit-profile-body-wrap {
  border-top: 1px solid #ededed;
  padding: 40px 0 0;
}
.settings-edit-profile-body-wrap .wal-form-group {
  margin-bottom: 40px;
}
.settings-edit-profile-buttons {
  position: absolute;
  top: -70px;
  right: 16px;
  display: flex;
}
.settings-edit-profile-buttons .button {
  width: 180px;
}
.settings-edit-profile-buttons .button:not(:last-of-type) {
  margin-right: 24px;
}
.settings-change-password {
  overflow-y: auto;
  height: 100%;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
  padding: 40px;
}
.settings-change-password::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-change-password::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-change-password::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-change-password .form-border {
  border-right: 1px solid #ededed;
}
.settings-change-password-form {
  padding-right: 40px;
}
.settings-change-password-form .wal-form-group {
  margin-bottom: 65px;
}
.settings-change-password-content {
  padding-left: 40px;
}
.settings-change-password-content p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-change-password-button {
  padding-right: 40px;
}
.settings-payments-wrap {
  padding: 40px;
}
.settings-payments-wrap .wal-cards-wrap {
  height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 8px;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.settings-payments-wrap .wal-cards-wrap::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
}
.settings-payments-wrap .wal-cards-wrap::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-payments-wrap .wal-cards-wrap::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-payments-main-title {
  padding-left: 40px;
  margin-bottom: 32px;
}
.settings-payments-main-title h2 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-payments-form {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 40px;
  background-color: rgba(240, 240, 240, 0.2);
}
.settings-payments-form-wrap {
  padding: 40px 0;
  border-top: 1px solid #ededed;
}
.settings-subscription {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.settings-subscription-bills {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bea58b;
  border-radius: 10px;
  padding: 16px 0;
  background-color: #ffffff;
}
.settings-subscription-bills-content {
  padding: 0 40px;
}
.settings-subscription-bills-content h2 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-subscription-bills-info {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.settings-subscription-bills-info span {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  text-transform: uppercase;
}
.settings-subscription-bills-info span:not(:last-of-type) {
  margin-right: 32px;
}
.settings-subscription-bills-price {
  padding: 0 40px;
  border-left: 1px solid #bea58b;
}
.settings-subscription-bills-price span {
  font-size: 24px;
  font-weight: 700;
  color: #191d26;
}
.settings-subscription-info {
  padding: 40px 0;
  text-align: center;
}
.settings-subscription-info p {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
}
.settings-subscription-top {
  padding: 0 40px;
}
.settings-subscription-top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 40px 40px;
  border-bottom: 1px solid #ededed;
}
.settings-subscription-top-left {
  display: flex;
  align-items: center;
  gap: 24px;
}
.settings-subscription-top-left h1 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-subscription-top-left .plan {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #191d26;
  text-transform: uppercase;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid #bea58b;
}
.settings-subscription-top-right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.settings-subscription-top-right .button {
  width: auto;
}
.settings-subscription-content {
  padding: 0 40px;
  background-color: rgba(237, 237, 237, 0.2);
}
.settings-subscription-content-wrap {
  padding: 40px;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.settings-subscription-content-item .title {
  font-size: 14px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 8px;
  display: block;
}
.settings-subscription-content-item p {
  font-size: 14px;
  font-weight: 500;
  color: #191d26;
  display: flex;
  align-items: center;
}
.settings-subscription-content-item p i {
  margin-left: 8px;
}
.settings-subscription-content-item p .price {
  font-size: 24px;
  font-weight: 700;
}
.settings-subscription-content-item p .month {
  font-weight: 300;
  color: #9d9d9e;
}
.settings-subscription-content-item .status {
  display: block;
  width: 90px;
  height: auto;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 300;
}
.settings-subscription-content-item .status.active {
  background-color: #e7f9e4;
  color: #2a9f31;
}
.settings-subscription-content-item .status.inactive {
  background-color: #fcf2f4;
  color: #d92344;
}
.settings-subscription-content-item .subscription-info-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.settings-subscription-content-item .subscription-info-tooltip:hover .info-tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}
.settings-subscription-content-bottom {
  display: flex;
  align-items: center;
  gap: 9px;
}
.settings-invoices {
  height: 100%;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
  background-color: rgba(237, 237, 237, 0.2);
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}
.settings-invoices::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-invoices::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-invoices::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-invoices-title {
  padding: 0 40px;
}
.settings-invoices-title-wrap {
  padding: 40px 40px 24px;
  border-bottom: 1px solid #ededed;
}
.settings-invoices-title-wrap h1 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-invoices-title-wrap h1 span {
  font-weight: 400;
  color: #9d9d9e;
  margin-left: 8px;
}
.settings-invoices-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 7px;
}
.settings-invoices-item:not(:last-of-type) {
  margin-bottom: 8px;
}
.settings-invoices-item.failed .info {
  opacity: 0.7;
}
.settings-invoices-item .info {
  font-size: 16px;
  font-weight: 400;
  color: #191d26;
}
.settings-invoices-item .invoices-status {
  width: 150px;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  border-radius: 16px;
}
.settings-invoices-item .invoices-status.active {
  background-color: #e7f9e4;
  color: #2a9f31;
}
.settings-invoices-item .invoices-status.failed {
  background-color: #fcf2f4;
  color: #d92344;
}
.settings-invoices-item-action {
  display: flex;
  align-items: center;
}
.settings-invoices-item-action .wal-icons-button {
  height: 40px;
  width: 40px;
  border-color: #ededed;
  font-size: 16px;
  background-color: #ffffff;
  margin-left: 0px;
}
.settings-invoices-item-action .wal-icons-button i::before {
  color: #191d26;
}
.settings-invoices-item-action .wal-icons-button:not(:last-of-type) {
  margin-right: 24px;
}
.settings-report {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.settings-report::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-report::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-report::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-report-top {
  padding: 0 40px;
}
.settings-report-top-wrap {
  padding: 40px 0;
  border-bottom: 1px solid #ededed;
}
.settings-report-top h2 {
  font-size: 16px;
  font-weight: 500;
  color: #191d26;
}
.settings-report-top p {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  margin-top: 24px;
}
.settings-report-form {
  padding: 0 40px;
  background-color: rgba(240, 240, 240, 0.2);
}
.settings-report-form-wrap {
  padding: 40px 0;
  border-bottom: 1px solid #ededed;
}
.settings-report-form textarea.wal-form-control {
  height: 100px;
}
.settings-report-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 60px;
}
.settings-report-upload .info {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9e;
  margin-bottom: 16px;
}
.settings-report-upload .wal-form-profile-upload .button {
  width: 312px;
}
.settings-report-footer {
  padding: 0 40px;
  margin-top: auto;
}
.settings-report-footer-wrap {
  padding: 40px 0;
  border-top: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settings-report-footer-wrap .button {
  width: 312px;
  max-width: 100%;
}
.settings-report-img {
  position: relative;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settings-report-img-wrap {
  position: relative;
}
.settings-report-img img {
  width: 230px;
  height: 270px;
  box-shadow: 1px 7px 14px 0px rgba(0, 0, 0, 0.12);
  object-fit: cover;
  max-width: 100%;
  height: auto;
}
.settings-report-img-remove {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.settings-report-img-remove i::before {
  color: #d92344;
}
.settings-invite {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.settings-invite::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-invite::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-invite::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-invite-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.settings-invite-top span.info {
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #bea58b;
  background-color: rgba(190, 165, 139, 0.1);
}
.settings-invite-wrap .form-border {
  border-right: 1px solid #ededed;
}
.settings-invite-form {
  padding-right: 40px;
}
.settings-invite-content {
  padding-left: 40px;
}
.settings-invite-content p {
  font-size: 14px;
  font-weight: 300;
  color: #191d26 !important;
}
.settings-invite-list-item {
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ededed;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 8px;
}
.settings-invite-list-item .email {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  flex: 1;
  margin-right: 16px;
}
.settings-invite-list-item .date {
  font-size: 14px;
  font-weight: 300;
  color: rgba(25, 29, 38, 0.6);
}
.settings-invite-list-item-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.settings-page {
  height: 100%;
  padding: 8px;
}
.settings-page-wrap {
  height: 100%;
  overflow-y: auto;
  word-break: break-word;
  padding: 32px;
  scrollbar-color: #c1beb9 #f0f0f0;
  scrollbar-width: thin;
}
.settings-page-wrap::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
.settings-page-wrap::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.settings-page-wrap::-webkit-scrollbar-thumb {
  background: #c1beb9;
}
.settings-page-wrap h4 {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  margin-bottom: 30px;
}
.settings-page-wrap h3 {
  font-size: 14px;
  font-weight: 500;
  color: #191d26;
  margin-top: 40px;
}
.settings-page-wrap p {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
  margin-top: 30px;
}
.settings-page-wrap a {
  transition: all 0.5s ease;
}
.settings-page-wrap a:hover {
  color: #bea58b;
  transition: all 0.5s ease;
}
.settings-page-wrap span {
  color: #bea58b;
  font-weight: 700;
}
.settings-page-wrap b {
  font-weight: 700;
}
.settings-page-wrap ul li {
  font-size: 14px;
  font-weight: 300;
  color: #191d26;
}
.settings-download {
  position: absolute;
  right: 16px;
  top: -70px;
}
.settings-download .button {
  width: 185px;
  max-width: 100%;
}

/* Range Slider Style
--------------------------------------------------------------*/
.rangeslider {
  box-shadow: none;
  background-color: #ededed;
}
.rangeslider-horizontal {
  height: 4px;
  border-radius: 2px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #bea58b;
}
.rangeslider-horizontal .rangeslider__handle::after {
  content: none;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -25px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip span {
  margin-top: 0px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip::after {
  content: none;
}
.rangeslider .rangeslider__fill {
  box-shadow: none;
}
.rangeslider .rangeslider__handle {
  background: #bea58b;
  border: 0;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  height: 16px;
  width: 16px;
  box-shadow: none;
  outline: none;
}
.rangeslider .rangeslider__handle-tooltip {
  width: auto;
  height: auto;
  background-color: transparent;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 300;
  color: #bea58b;
}

/* React Style
--------------------------------------------------------------*/
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  top: -47px !important;
}

/* Responsive Style
-------------------------------------------------------------- */
@media (max-width: 1439px) {
  .pd-rl {
    padding: 0 30px;
  }
  .settings-tab-item-info i {
    font-size: 38px;
  }
}
@media (max-width: 1400px) {
  .mg-top-260 {
    margin-top: 160px;
  }
}
@media (max-width: 1199px) {
  .mg-top-145 {
    margin-top: 100px;
  }
  .main-menu-indicator {
    display: block;
  }
  .wal-filter {
    margin-bottom: 30px;
  }
  .lawyers-region {
    margin-bottom: 30px;
  }
  .header-wrap {
    justify-content: space-between;
    padding: 30px 0;
  }
  .header-nav {
    transform: translatex(100%);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #191d26;
    z-index: 99;
    transition: transform 0.4s ease-in-out;
    display: flex;
    align-items: flex-start;
    margin-top: 0;
    padding: 80px 24px 40px;
    text-align: center;
  }
  .header-nav.is-open {
    transform: translateX(0);
  }
  .header-nav ul li {
    display: block;
    position: relative;
    margin: 0;
  }
  .header-nav ul li:not(:last-child) {
    margin-bottom: 30px;
  }
  .header-nav ul li a {
    font-size: 24px;
    padding: 0px;
  }
  .header-nav ul li a::before {
    content: none;
  }
  .header-right {
    margin-right: 50px;
  }
  .header-button {
    padding-right: 50px;
  }
  .profile-knowledge {
    margin-bottom: 60px;
  }
  .profile-aside {
    margin-top: 40px;
  }
  .download-app-wrap {
    padding: 40px 20px;
  }
  .download-app-img {
    bottom: -40px;
  }
  .toggle-open {
    overflow: hidden;
  }
  .messages-item .messages-content {
    max-width: 50%;
  }
  .settings-top-title {
    margin-bottom: 40px;
  }
  .settings-sidebar {
    transform: translatex(-100%);
    position: fixed;
    width: 50%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #ffffff;
    z-index: 4;
    transition: transform 0.4s ease-in-out;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    border: none;
    border-radius: 0px;
  }
  .settings-sidebar.is-open {
    transform: translateX(0);
  }
  .settings-sidebar-icon {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
  .settings-content {
    height: auto;
  }
  .settings-content .accordion-head-wrap {
    padding: 40px 0;
  }
  .settings-content .accordion-body-wrap {
    padding: 40px 0;
  }
  .settings-tab-item-info p {
    font-size: 20px;
    margin-left: 16px;
  }
  .settings-history-list {
    height: 550px;
  }
  .settings-payments-form {
    position: relative;
  }
  .settings-payments-wrap .wal-cards-wrap {
    height: auto;
  }
  .settings .credit-card-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .settings .credit-card-buttons .button:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .settings-invoices {
    height: 550px;
  }
  .settings-report-form-wrap {
    border-bottom: none;
  }
  .settings-page {
    padding: 0px;
  }
}
@media (max-width: 1024px) {
  .download-app-content img {
    margin-bottom: 30px;
  }
  .download-app-content h1 {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .pd-rl {
    padding: 0px;
  }
  .mg-top-260 {
    margin-top: 80px;
  }
  .mg-top-160 {
    margin-top: 60px;
  }
  .mg-top-145 {
    margin-top: 80px;
  }
  .wal-content-title {
    margin-bottom: 30px;
  }
  .wal-form-date .wal-form-control {
    padding-right: 10px;
    padding-left: 35px;
  }
  .wal-form-date .wal-form-control i {
    left: 10px;
  }
  .hero {
    height: 700px;
    padding-bottom: 100px !important;
  }
  .hero-content h1 {
    font-size: 30px;
  }
  .hero-img {
    right: -300px;
  }
  .court-section-two {
    padding-top: 40px;
  }
  .court-section-reverse {
    flex-direction: column-reverse;
  }
  .court-section-content {
    text-align: center;
  }
  .court-section-content .button {
    width: 160px;
    margin: 0 auto;
  }
  .profile-wrap {
    height: auto;
  }
  .profile-info {
    margin-bottom: 30px;
  }
  .profile-aside {
    border-left: 0px;
    padding-left: 0px;
  }
  .profile-buttons .button {
    width: 100%;
  }
  .lawfirm-profile-info {
    margin-bottom: 0px;
  }
  .messages-sidebar {
    margin-bottom: 40px;
  }
  .messages-sidebar .messages-list-options {
    visibility: visible;
    opacity: 1;
    display: block !important;
  }
  .messages-item .messages-file {
    max-width: 50%;
  }
  .notifications-item {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
  .notifications-item-left {
    margin-right: 0px;
    width: 100%;
    padding-right: 50px;
  }
  .notifications-item-right {
    width: 100%;
    justify-content: space-between;
  }
  .notifications-item-buttons {
    margin-top: 24px;
    margin-right: 0px;
  }
  .notifications-item-delete {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .settings-edit-profile-top {
    padding: 30px 20px;
  }
  .settings-edit-profile-body {
    padding: 0 20px;
  }
  .settings-edit-profile-buttons {
    position: relative;
    top: 0;
    right: 0;
    justify-content: flex-end;
    padding: 0 40px 30px;
  }
  .settings-change-password {
    padding: 40px 20px;
  }
  .settings-payments-wrap {
    padding: 40px 20px;
  }
  .settings-tab {
    padding: 0px 20px;
  }
  .settings-content .wal-content-head {
    padding: 0 20px;
  }
  .settings-history-list {
    padding: 16px 8px 16px 20px;
  }
  .settings-subscription-top {
    padding: 0 20px;
  }
  .settings-subscription-top-wrap {
    padding: 40px 0;
  }
  .settings-subscription-content {
    padding: 0 20px;
  }
  .settings-subscription-content-wrap {
    padding: 40px 0;
  }
  .settings-subscription-content-item .subscription-info-tooltip .info-tooltip {
    left: auto;
    transform: none;
    right: -20px;
  }
  .settings-subscription-content-item .subscription-info-tooltip .info-tooltip::before {
    left: auto;
    transform: none;
    right: 20px;
  }
  .settings-invoices {
    padding: 20px;
  }
  .settings-invoices-title {
    padding: 0 20px;
  }
  .settings-invoices-title-wrap {
    padding: 40px 0 24px;
  }
  .settings-download {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 30px;
  }
  .settings-page-wrap {
    padding: 30px 20px;
  }
  .settings-report-top {
    padding: 0 20px;
  }
  .settings-report-form {
    padding: 0 20px;
  }
  .settings-report-footer {
    padding: 0 20px;
  }
  .settings-content .accordion-head, .settings-content .accordion-body {
    padding: 0 20px;
  }
  .footer-info {
    text-align: center;
    height: auto;
    align-items: center;
    margin-bottom: 60px;
  }
  .footer-bottom-right {
    justify-content: center;
    gap: 24px;
    margin-bottom: 20px;
  }
  .footer-order {
    order: 3;
  }
  .footer-register {
    justify-content: center;
    margin-bottom: 20px;
  }
  .footer-social {
    margin-top: 30px;
  }
  .footer-copyright {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .mg-top-220 {
    margin-top: 140px;
  }
  .mg-top-180 {
    margin-top: 120px;
  }
  .main-title {
    flex-direction: column;
    text-align: center;
  }
  .main-title h1 {
    margin-bottom: 8px;
  }
  .header-wrap {
    padding: 20px 0;
  }
  .header-logo {
    margin-right: 0px;
  }
  .header-logo img {
    max-width: 80%;
  }
  .header-right {
    width: 100%;
    margin-right: 40px;
    justify-content: flex-end;
  }
  .header-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .header-nav ul li {
    margin-bottom: 24px;
  }
  .header-nav ul li a {
    font-size: 18px;
  }
  .header-messages-menu {
    width: 300px;
    top: 40px;
    right: -50px;
    left: auto;
  }
  .header-messages-body {
    max-height: 300px;
  }
  .header-notifications-menu {
    width: 300px;
    top: 40px;
    right: -50px;
    left: auto;
  }
  .header-notifications-body {
    max-height: 300px;
  }
  .header-profile-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
  }
  .header-profile-img {
    display: none;
    visibility: hidden;
    opacity: 0;
    width: 38px;
    height: 38px;
    flex: 0 0 38px;
  }
  .header-profile-name {
    display: none;
    visibility: hidden;
    opacity: 0;
    padding-right: 30px;
  }
  .header-profile-name h1 {
    font-size: 14px;
  }
  .header-profile .dropdown-menu {
    top: 40px;
    right: -50px;
  }
  .header-button {
    padding-right: 40px;
  }
  .header-button a:not(:last-of-type) {
    margin-right: 0px;
  }
  .header-button .button-login, .header-button .button-register {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .header-button-mobile {
    display: block;
    visibility: visible;
    opacity: 1;
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-top: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
  }
  .header-button-mobile .button {
    width: 100%;
  }
  .wal-content-head {
    margin-bottom: 10px;
  }
  .wal-content-head .wal-form-group {
    margin-bottom: 30px;
  }
  .wal-content-button {
    margin-bottom: 30px;
  }
  .wal-form-sort {
    flex-direction: column;
    align-items: flex-start;
  }
  .wal-form-sort label {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .wal-form-type {
    margin-bottom: 10px;
  }
  .wal-form-type .radio:checked + label .wal-form-warning .info-tooltip, .wal-form-type .radio:not(:checked) + label .wal-form-warning .info-tooltip {
    left: 0;
    right: auto;
  }
  .wal-form-type .radio:checked + label .wal-form-warning .info-tooltip::before, .wal-form-type .radio:not(:checked) + label .wal-form-warning .info-tooltip::before {
    right: auto;
    left: 10px;
  }
  .wal-form-date-menu {
    width: 100%;
  }
  .wal-form-date .calendar {
    padding: 8px;
    flex-direction: column;
  }
  .wal-form-date .calendar-sidebar {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .wal-form-date .calendar-sidebar ul {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
  }
  .wal-form-date .calendar-sidebar ul li:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 8px;
  }
  .wal-filter {
    min-height: auto;
    height: auto;
    padding: 0 16px;
  }
  .wal-search-result-top {
    margin-bottom: 30px;
  }
  .wal-search-result-top .title,
.wal-search-result-top .result {
    font-size: 16px;
  }
  .wal-cards-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .wal-cards-item div {
    word-break: break-all;
  }
  .wal-cards-item div:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .wal-cards-delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .wal-results {
    padding: 0;
    height: auto;
    border-radius: 0;
    border: none;
  }
  .wal-results-wrap {
    height: auto;
    padding-right: 0px;
  }
  .hero {
    height: auto;
    padding-top: 120px;
    padding-bottom: 50px !important;
  }
  .hero-wrap {
    flex-direction: column-reverse;
  }
  .hero-content h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .hero-content h1::before {
    bottom: -10px;
  }
  .hero-img {
    right: 0;
    top: 0;
    width: 100%;
    height: 400px;
    opacity: 0.2;
  }
  .hero-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hero-lawyers {
    margin-bottom: 50px;
  }
  .hero-lawyers span {
    font-size: 40px;
  }
  .hero-button {
    flex-direction: column;
  }
  .hero-button .button {
    width: 100%;
  }
  .hero-button .button:first-child {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .wal-video {
    display: none;
  }
  .wal-video-button {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .wal-video-button.mobile {
    display: block;
    margin-top: 16px;
  }
  .lawyers-img img {
    height: 46px;
    width: 46px;
  }
  .lawyers-title {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    padding-right: 30px;
  }
  .lawyers-title .lawyers-type {
    margin-top: 8px;
  }
  .lawyers-type {
    margin-left: 0px;
  }
  .lawyers-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .lawyers-info span {
    margin-left: 0px;
  }
  .lawyers-info span.type {
    white-space: normal;
    width: auto;
    flex: auto;
  }
  .lawyers-info-region-bottom {
    flex-wrap: wrap;
  }
  .lawyers-content {
    width: 100%;
  }
  .lawyers-region {
    height: auto;
  }
  .lawyers-region-map {
    height: 400px;
  }
  .lawfirm-offices-item {
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
  .verify-badge-tooltip {
    left: auto;
    right: -10px;
    transform: none;
  }
  .contracts-item {
    flex-direction: column;
  }
  .contracts-item-wrap {
    flex-direction: column;
  }
  .contracts-left {
    width: 100%;
    align-items: stretch;
  }
  .contracts-right {
    width: 100%;
    padding: 16px;
  }
  .contracts-center {
    margin-right: 0px;
    padding: 16px;
  }
  .contracts-img {
    width: 100%;
    margin-right: 0px;
    padding: 10px 0;
  }
  .contracts-purchase {
    padding: 10px 0;
  }
  .contracts-content-wrap {
    margin-right: 0px;
  }
  .contracts-info {
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .contracts-info span {
    margin-left: 0px;
  }
  .contracts-info p {
    white-space: normal;
  }
  .contracts-info-wrap {
    flex-direction: column;
  }
  .contracts-bookmark {
    margin-right: 16px;
  }
  .contracts-bookmark a {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
  }
  .contracts-purchase {
    height: 40px;
    width: 100%;
    border-radius: 7px;
  }
  .contracts-purchase i {
    font-size: 16px;
  }
  .contracts-share {
    margin-right: 16px;
  }
  .contracts-share a {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
  }
  .profile-wrap {
    padding-top: 120px;
  }
  .profile-info {
    flex-direction: column;
    align-items: flex-start;
  }
  .profile-info-wrap {
    margin-top: 30px;
    text-align: center;
    width: 100%;
  }
  .profile-info-top {
    justify-content: center;
  }
  .profile-name {
    justify-content: center;
  }
  .profile-name h1 {
    font-size: 22px;
  }
  .profile-image {
    margin: 0 auto;
  }
  .profile-status {
    justify-content: center;
  }
  .profile-rating {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .profile-rating-state {
    justify-content: center;
  }
  .profile-type {
    justify-content: center;
    flex-direction: column;
  }
  .profile-type .type {
    margin-right: 0px;
  }
  .profile-article ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .profile-reviews-top {
    flex-direction: column;
  }
  .profile-reviews-input {
    margin-top: 40px;
    width: 100%;
  }
  .knowledge-item {
    margin-bottom: 30px;
  }
  .knowledge-item-list-wrap {
    flex-direction: column;
  }
  .knowledge-item-list-left {
    flex-direction: column;
  }
  .knowledge-item-list-img {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .knowledge-item-list-info {
    flex-wrap: wrap;
  }
  .knowledge-item-list-info span {
    margin-bottom: 8px;
  }
  .knowledge-item-list-info span.type {
    flex: none;
  }
  .knowledge-item-list-view {
    display: none;
  }
  .articles-type {
    flex-direction: column;
  }
  .articles-type .more-list ul {
    min-width: 240px;
    left: 50%;
    transform: translateX(-50%);
  }
  .comments-info-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
  .modal-ge-wrap {
    padding: 0 20px;
  }
  .modal-ge-close {
    top: 10px;
    right: 10px;
  }
  .login-close {
    top: 10px;
    right: 10px;
  }
  .login-wrap {
    padding: 0 20px;
  }
  .login-other {
    flex-direction: column;
  }
  .login-other a:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .login-other::before {
    width: calc(100% + 20px);
  }
  .payment-modal-info::before {
    width: calc(100% + 20px);
  }
  .search-close {
    right: 0px;
  }
  .search-form .search-input {
    width: 90%;
  }
  .search-result-text {
    flex-direction: column;
    align-items: flex-start;
  }
  .search-result-text h1 {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .search-suggestion {
    padding-left: 0px;
  }
  .messages {
    margin-top: 80px;
  }
  .messages-item .messages-content {
    max-width: 100%;
  }
  .messages-item .messages-location-bottom {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .messages-item .messages-location-bottom .time {
    margin-bottom: 8px;
  }
  .messages-item-action .more-options-menu {
    left: auto;
    right: -8px;
  }
  .messages-item .messages-file {
    max-width: 100%;
  }
  .messages-item .messages-location {
    width: 100%;
  }
  .messages-item.self .messages-item-action .more-options-menu {
    right: auto;
    left: -8px;
  }
  .messages-empty {
    padding: 0 20px;
  }
  .messages-box-header-wrap {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .messages-box-body {
    padding: 16px 8px 8px 16px;
  }
  .messages-box-user {
    width: 100%;
    margin-bottom: 20px;
  }
  .messages-box-action {
    width: 100%;
    justify-content: space-evenly;
  }
  .messages-box-action div:not(:last-of-type) {
    margin-right: 16px;
  }
  .messages-box-action .messages-delete-icon {
    display: none;
    visibility: hidden;
  }
  .messages-box-action .more-options {
    position: relative;
  }
  .messages-box-footer {
    padding: 16px;
  }
  .messages-box-footer-inner {
    flex-direction: column;
    align-items: flex-start;
  }
  .messages-box-footer-wrap {
    margin-bottom: 16px;
  }
  .messages-box-input {
    margin: 0 0 0 16px;
  }
  .messages-box-send {
    width: 100%;
  }
  .messages-box-request {
    margin-top: 30px;
  }
  .messages-box-request-buttons {
    margin-top: 20px;
  }
  .messages-box-search {
    padding: 12px 16px;
  }
  .messages-blocked-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .messages-blocked-buttons .button:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .messages-contracts {
    padding: 16px;
  }
  .messages-contracts .contracts-center {
    text-align: left;
    padding: 16px;
  }
  .messages-contracts .contracts-content {
    flex-direction: row;
  }
  .messages-contracts .contracts-icon {
    margin-bottom: 0px;
    margin-right: 16px;
    margin-left: 0px;
  }
  .messages-error {
    padding: 16px;
  }
  .info-tooltip {
    left: auto;
    transform: none;
    right: 0;
  }
  .info-tooltip::before {
    left: auto;
    transform: none;
    right: 10px;
  }
  .incoming-calls-modal .modal-content {
    padding: 0 15px;
  }
  .incoming-calls-modal-footer .button {
    margin-left: 15px;
  }
  .incoming-calls-modal-close {
    right: 15px;
  }
  .notifications {
    margin-top: 120px;
  }
  .notifications-item-left {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0px;
  }
  .notifications-item-img {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .notifications-item-info {
    margin-top: 8px;
  }
  .notifications-item-right {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .notifications-item-buttons {
    width: 100%;
    margin-right: 0px;
    flex-direction: column;
  }
  .notifications-item-buttons .button {
    width: 100%;
  }
  .notifications-item-buttons .button:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .settings {
    margin-top: 120px;
  }
  .settings-sidebar {
    width: 70%;
  }
  .settings-sidebar-icon {
    padding-left: 15px;
  }
  .settings-content .wal-content-head {
    padding: 0 15px;
  }
  .settings-content .accordion-head {
    padding: 0 15px;
  }
  .settings-content .accordion-body {
    padding: 0 15px;
  }
  .settings-tab {
    padding: 0 15px;
  }
  .settings-tab-item {
    width: 140px;
    flex: 0 0 140px;
  }
  .settings-tab-item-info i {
    font-size: 28px;
  }
  .settings-history-list {
    padding: 15px 8px 15px 15px;
  }
  .settings-history-list-wrap {
    padding-right: 14px;
  }
  .settings-history-item {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
  .settings-history-item-left {
    width: 100%;
  }
  .settings-history-item-content {
    width: 100%;
  }
  .settings-history-item-right {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
  .settings-history-item-action .wal-icons-button {
    margin-left: 8px;
  }
  .settings-modal-info::before {
    width: calc(100% + 20px);
  }
  .settings-modal-details-content-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .settings-modal-details-content-wrap p {
    white-space: normal;
    margin-right: 0px;
  }
  .settings-edit-profile-top {
    padding: 32px 15px;
  }
  .settings-edit-profile-top .wal-form-profile {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ededed;
  }
  .settings-edit-profile-top .wal-form-profile::before {
    content: none;
  }
  .settings-edit-profile-body {
    padding: 0 15px;
  }
  .settings-edit-profile-buttons {
    padding: 0 15px 30px;
  }
  .settings-change-password {
    padding: 20px;
  }
  .settings-change-password .form-border {
    border-right: none;
  }
  .settings-change-password-wrap {
    flex-direction: column-reverse;
  }
  .settings-change-password-form {
    padding-right: 0px;
  }
  .settings-change-password-form .wal-form-group {
    margin-bottom: 45px;
  }
  .settings-change-password-content {
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .settings-change-password-button {
    padding-right: 0px;
  }
  .settings-payments-wrap {
    padding: 40px 15px;
  }
  .settings-payments-form {
    padding: 0 15px;
  }
  .settings-page-wrap {
    padding: 8px;
  }
  .settings-report-top {
    padding: 0 15px;
  }
  .settings-report-form {
    padding: 0 15px;
  }
  .settings-report-upload .wal-form-profile-upload .button {
    width: 100%;
    padding: 0 10px;
  }
  .settings-report-footer {
    padding: 0 15px;
  }
  .settings-invite-wrap {
    flex-direction: column-reverse;
  }
  .settings-invite-wrap .form-border {
    border-right: none;
  }
  .settings-invite-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .settings-invite-top .info {
    margin-top: 20px;
  }
  .settings-invite-content {
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .settings-invite-form {
    padding-right: 0px;
  }
  .settings-invite-list-item {
    padding: 15px;
    flex-direction: column;
  }
  .settings-invite-list-item .email {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .settings-invite-list-item-right {
    flex-direction: column;
  }
  .settings-invite-list-item-right .date {
    margin-bottom: 8px;
  }
  .settings-subscription-top {
    padding: 0 15px;
  }
  .settings-subscription-top-left, .settings-subscription-top-right {
    width: 100%;
  }
  .settings-subscription-top-left {
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .settings-subscription-top-right .button {
    width: 100%;
  }
  .settings-subscription-top-wrap {
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .settings-subscription-content {
    padding: 0 15px;
  }
  .settings-subscription-content-wrap {
    padding: 20px 0;
    flex-direction: column;
  }
  .settings-subscription-content-item .subscription-info-tooltip .info-tooltip {
    left: 50%;
    transform: translateX(-50%);
  }
  .settings-subscription-content-item .subscription-info-tooltip .info-tooltip::before {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
  .settings-invoices {
    padding: 20px 15px;
  }
  .settings-invoices-title {
    padding: 0 15px;
  }
  .settings-invoices-title-wrap {
    padding: 20px 0;
  }
  .settings-invoices-item {
    padding: 10px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .settings-invoices-item .invoices-status {
    width: 100%;
  }
  .settings-invoices-item-action {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .subscription-plan-wrap {
    flex-direction: column;
    align-items: center;
  }
  .subscription-plan-item:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  .subscription-modal-wrap {
    padding: 30px 15px;
  }
  .upload-contract-modal .upload-contract-wrap {
    padding: 0 15px;
  }
  .upload-contract-modal .upload-contract-top-wrap {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
  }
  .upload-contract-modal .upload-contract-top-wrap .wal-form-group {
    width: 100%;
  }
  .upload-contract-modal .upload-contract-top-wrap span {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .video-calls-wrap .video-small {
    bottom: 120px;
    right: 15px;
    height: 120px;
    width: 100px;
  }
  .video-calls-top {
    padding: 15px;
  }
  .video-calls-top .action .icon:not(:last-of-type) {
    margin-right: 15px;
  }
  .no-camera-found {
    position: absolute;
    width: 100%;
    top: 90px;
    left: 0;
  }
  .download-app-wrap {
    overflow: hidden;
    padding: 40px 30px 0px 30px;
  }
  .download-app-reverse {
    flex-direction: column-reverse;
  }
  .download-app-img {
    text-align: center;
    position: relative;
    margin-top: 60px;
    bottom: 0px;
  }
  .download-app-content h1 {
    font-size: 24px;
  }
  .download-app-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .download-app-buttons a:not(:last-of-type) {
    margin-right: 0px;
    margin-bottom: 24px;
  }
  .download-app .button {
    width: 100%;
  }
  .footer-top {
    padding: 60px 0 20px;
  }
  .footer-bottom-right {
    flex-direction: column;
  }
  .footer-links {
    margin-bottom: 40px;
  }
  .footer-links.center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .panel {
    border: none;
    box-shadow: none;
  }
  .panel-heading {
    display: none;
  }
  .panel-collapse {
    height: auto;
  }
  .panel-collapse.collapse {
    display: block;
  }
}
@media (max-width: 767px) {
  .profile-tab {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .profile-tab-content .tab-pane {
    display: block;
  }
  .panel {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
  .panel-title a {
    display: block;
  }
  .nav-content {
    padding: 20px 0;
  }
}