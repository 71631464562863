/* .messages-box, .messages-sidebar {
    height: 85vh !important;
} */

/* .wal-form-date .calendar {
  position: relative;
  display: flex;
  padding: 40px 32px 32px 40px;
} */

.rdrMonthName {
  display: none !important;
}

.Toastify__toast {
  background: #fff;
  color: black;
  font-family: 'Steradian';
  font-size: 14px;
}

.Toastify__close-button{
  color: black;
}

.customHashtags {
  cursor: default;
  color: rgba(255, 255, 255, 0.5) !important;
}

.profile-wrap {
  height: auto !important;
}

.footer-register a {
  color: rgba(255, 255, 255, 0.5);
}

.footer-register a:hover {
  cursor: pointer;
  color: #fff;
  transition: color 0.5s ease;
}

.phoneInput-registration {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9e;
  margin-bottom: 8px;
}

.profile-create-img img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover
}

.header-about-us {
  white-space: nowrap;
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, 0.1);
}

.header-about-us a {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  padding: 6px 0 6px 0;
  color: #9d9d9e;
  transition: all .5s ease;
  margin-left: 32px;
  margin-right: 32px;
}

.header-about-us a:hover {
  color: #bea58b;
}

.logoutMobileButton {
  height: 48px !important;
  background-color: rgba(217, 35, 68, 0.05);
  border: none;
  color: #d92344 !important;
}

.logoutMobileButton i::before {
  color: #d92344 !important;
}

.data-deletion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.data-deletion-title {
  margin-bottom: 20px
}

.data-deletion-text {
  color:rgb(69, 197, 69);
  font-weight: normal;
}

.data-deletion-error {
  color:rgb(197, 69, 69);
  font-weight: normal;
}
.card-container {
  flex: 1;
  overflow: hidden;
}

.description-text {
 max-width: 400px;
}

.wal-video-home {
  position: fixed;
  width: auto;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  transition: all 0.3s ease;
}
.wal-video-button-home {
  width: 100%;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  border: none;
  border-radius: 7px;
  background-color: #bea58b;
  outline: 0;
  transition: all 0.3s ease;
}

.wal-video-button-home:hover {
  background-color: #ac8c6a;
}

.wal-video-title-home h1 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
}

.wal-video-button-home svg {
  fill: #ffffff;
  margin-left: 8px;
}

.wal-video-home.hide {
  right: -25em;
}
